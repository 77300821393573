// DynamicComponentLoader.jsx
import React, { useState, useEffect } from 'react';
import * as Recharts from 'recharts';
window.React = React; // Add this in your DynamicComponentLoader
window.Recharts = Recharts; // Add this in your DynamicComponentLoader

const DynamicComponentLoader = ({ url, ...props }) => {
  const [Component, setComponent] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadComponent = async () => {
      try {
        // Make React available globally for the remote component
        window.React = React;
        window.Recharts = Recharts;
        
        // Create a unique ID for this component
        const componentId = `remote-component-${Math.random().toString(36).substr(2, 9)}`;
        
        // Create a script element
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.id = componentId;
        
        // Create a promise to wait for the script to load and register the component
        const loadPromise = new Promise((resolve, reject) => {
          // Define a global callback that will be called by the remote component
          window[`${componentId}Callback`] = (exportedComponent) => {
            resolve(exportedComponent);
          };
          
          script.onerror = () => reject(new Error('Failed to load component script'));
        });

        // Add the script to the document
        document.head.appendChild(script);
        
        // Wait for the component to load and register
        const loadedComponent = await loadPromise;
        setComponent(() => loadedComponent);
        
        // Cleanup
        delete window[`${componentId}Callback`];
      } catch (err) {
        setError(err);
        console.error('Error loading component:', err);
      } finally {
        setLoading(false);
      }
    };

    loadComponent();

    // Cleanup function
    return () => {
      const scripts = document.getElementsByTagName('script');
      for (let script of scripts) {
        if (script.src === url) {
          document.head.removeChild(script);
        }
      }
    };
  }, [url]);

  if (loading) {
    return <div>Loading component...</div>;
  }

  if (error) {
    return <div>Error loading component: {error.message}</div>;
  }

  return <div style={{
    display: 'flex',
    margin: 10,
    background: "#FFF",
    border: "1px solid #DDD",
    borderRadius: 5,
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    padding: 20,
    flexDirection: 'column',
  }}>
    <div style={{
      fontSize: 14,
      fontWeight: 'bold',
      marginBottom: 10,
      background: "#F9F9F9",
    }}>Header</div>
    {Component ? <Component {...props} /> : null}
  </div>;
};

export default DynamicComponentLoader;