// src/components/EventNodeForm.jsx

import React, { useState, useCallback, useEffect } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';

const EventNodeForm = ({ node, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: node.data.name || '',
    description: node.data.description || '',
    fields: node.data.fields || []
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    setFormData({
      name: node.data.name || '',
      description: node.data.description || '',
      fields: node.data.fields || []
    });
  }, [node]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }, []);

  const handleAddField = useCallback(() => {
    setFormData(prevData => ({
      ...prevData,
      fields: [...prevData.fields, { id: Date.now(), name: '', type: 'string', required: false }]
    }));
  }, []);

  const handleFieldChange = useCallback((id, key, value) => {
    setFormData(prevData => ({
      ...prevData,
      fields: prevData.fields.map(field =>
        field.id === id ? { ...field, [key]: value } : field
      )
    }));
  }, []);

  const handleDeleteField = useCallback((id) => {
    setFormData(prevData => ({
      ...prevData,
      fields: prevData.fields.filter(field => field.id !== id)
    }));
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setError(null);
    
    // Basic validation
    if (!formData.name.trim()) {
      setError('Event name is required');
      return;
    }

    if (formData.fields.some(field => !field.name.trim())) {
      setError('All fields must have a name');
      return;
    }

    onSubmit(node.id, formData).catch(err => {
      setError(`Failed to update event: ${err.message}`);
    });
  }, [node.id, formData, onSubmit]);

  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <div style={{ color: 'red', marginBottom: '10px' }}>
          {error}
        </div>
      )}
      <div>
        <label htmlFor="name">Event Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor="description">Description:</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Fields:</label>
        {formData.fields.map((field) => (
          <div key={field.id} style={{ marginBottom: '10px' }}>
            <input
              type="text"
              value={field.name}
              onChange={(e) => handleFieldChange(field.id, 'name', e.target.value)}
              placeholder="Field Name"
              required
            />
            <select
              value={field.type}
              onChange={(e) => handleFieldChange(field.id, 'type', e.target.value)}
            >
              <option value="string">String</option>
              <option value="number">Number</option>
              <option value="boolean">Boolean</option>
              <option value="file">File</option>
              <option value="object">Object</option>
              <option value="data">Date</option>
            </select>
            <label>
              <input
                type="checkbox"
                checked={field.required}
                onChange={(e) => handleFieldChange(field.id, 'required', e.target.checked)}
              />
              Required
            </label>
            <FaTrash
              onClick={() => handleDeleteField(field.id)}
              style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
            />
          </div>
        ))}
        <button type="button" onClick={handleAddField}>
          <FaPlus /> Add Field
        </button>
      </div>
      <button type="submit">Update Event</button>
    </form>
  );
};

export default React.memo(EventNodeForm);