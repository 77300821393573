// src/components/EventNode.jsx

import React, { useState, useCallback, useEffect } from "react";
import { Handle, Position } from "@xyflow/react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import {
  FaBolt,
  FaCode,
  FaPlus,
  FaTrash,
  FaWindowMaximize,
  FaWindowMinimize,
} from "react-icons/fa";
import AuthSettings from "./AuthSettings";

function CodeEditorPopup({ code, onSave, onClose }) {
  const [editorCode, setEditorCode] = useState(code);

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <AceEditor
          mode="javascript"
          theme="monokai"
          onChange={setEditorCode}
          value={editorCode}
          name="event_field_code_editor"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            useWorker: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
          style={{ width: "100%", height: "200px" }}
        />
        <div className="popup-actions">
          <button onClick={() => onSave(editorCode)}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

function EventNode({ data, id, onUpdate, selected, entities, roles, users }) {
  const [formData, setFormData] = useState({
    name: data.name || "",
    description: data.description || "",
  });

  const [fields, setFields] = useState(data.fields || []);
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [currentFieldIndex, setCurrentFieldIndex] = useState(null);
  const [minimized, setMinimized] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleAddField = useCallback(() => {
    const newField = {
      id: Date.now(),
      name: "",
      type: "string",
      required: false,
      code: "",
    };
    setFields((prevFields) => [...prevFields, newField]);
  }, []);

  const handleCodeButtonClick = useCallback((index) => {
    setCurrentFieldIndex(index);
    setShowCodeEditor(true);
  }, []);

  const handleCodeEditorSave = useCallback(
    (newCode) => {
      setFields((prevFields) => {
        const newFields = [...prevFields];
        newFields[currentFieldIndex].code = newCode;
        return newFields;
      });
      setShowCodeEditor(false);
    },
    [currentFieldIndex]
  );

  const handleDeleteField = useCallback((fieldId) => {
    setFields((prevFields) =>
      prevFields.filter((field) => field.id !== fieldId)
    );
  }, []);

  const handleFieldChange = useCallback((fieldId, key, value) => {
    setFields((prevFields) =>
      prevFields.map((field) =>
        field.id === fieldId ? { ...field, [key]: value } : field
      )
    );
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onUpdate(id, { ...formData, fields }).catch((err) => {
        setError(`Failed to update event: ${err.message}`);
      });
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [formData, fields, id, onUpdate]);

  return (
    <div
      className="event-node"
      style={{
        border: selected ? "2px solid #666" : "0px solid black",
        padding: "5px",
        borderRadius: "5px",
      }}
    >
      <div className="node-header">
        {minimized ? (
          <FaWindowMaximize onClick={() => setMinimized(false)} />
        ) : (
          <FaWindowMinimize onClick={() => setMinimized(true)} />
        )}
        <FaBolt />
        {formData.name || "Event"}
      </div>
      {error && (
        <div
          style={{
            backgroundColor: "#ffcccc",
            color: "red",
            padding: "5px",
            fontSize: "12px",
          }}
        >
          {error}
        </div>
      )}

      <>
        <div className="handles-container">
          <div className="input-handles">
            {fields.map((field) => (
              <div key={`${id}-input-${field.id}`} className="handle-wrapper">
                <Handle
                  type="target"
                  position={Position.Left}
                  id={`${id}-input-${field.id}`}
                  style={{ left: 6 }}
                />
                <span className="handle-label">
                  {field.name} ({field.type})
                </span>
              </div>
            ))}
          </div>
          <div className="output-handles">
            {fields.map((field) => (
              <div key={`${id}-output-${field.id}`} className="handle-wrapper">
                <span className="handle-label">
                  {field.name} ({field.type})
                </span>
                <Handle
                  type="source"
                  position={Position.Right}
                  id={`${id}-output-${field.id}`}
                  style={{ right: 6 }}
                />
              </div>
            ))}
          </div>
        </div>
        {!minimized && (
          <>
            <div className="node-content">
              <form className="node-form">
                <div className="form-field">
                  <label htmlFor="name">Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Event name"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="description">Description:</label>
                  <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Event description"
                  />
                </div>
                <AuthSettings
                  data={data}
                  id={id}
                  onUpdate={onUpdate}
                  selected={selected}
                  entities={entities}
                  users={users}
                  roles={roles}
                  setError={setError}
                />
                <div className="form-field">
                  <span
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label htmlFor="fields">Fields:</label>
                    <FaPlus
                      onClick={handleAddField}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                  <div className="fields-list">
                    {fields.map((field) => (
                      <div
                        key={field.id}
                        className="field"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "1px",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Field Name"
                          value={field.name}
                          style={{ width: "100px" }}
                          onChange={(e) =>
                            handleFieldChange(field.id, "name", e.target.value)
                          }
                        />
                        <select
                          value={field.type}
                          onChange={(e) =>
                            handleFieldChange(field.id, "type", e.target.value)
                          }
                        >
                          <option value="string">String</option>
                          <option value="number">Number</option>
                          <option value="boolean">Boolean</option>
                          <option value="file">File</option>
                          <option value="object">Object</option>
                          <option value="data">Date</option>
                        </select>
                        <label>
                          <input
                            type="checkbox"
                            checked={field.required}
                            onChange={(e) =>
                              handleFieldChange(
                                field.id,
                                "required",
                                e.target.checked
                              )
                            }
                          />
                          Required
                        </label>
                        <FaCode
                          onClick={() =>
                            handleCodeButtonClick(fields.indexOf(field))
                          }
                          style={{ cursor: "pointer", color: "blue" }}
                        />
                        <FaTrash
                          onClick={() => handleDeleteField(field.id)}
                          style={{ cursor: "pointer", color: "red" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </form>
            </div>
            {showCodeEditor && (
              <CodeEditorPopup
                code={fields[currentFieldIndex].code || ""}
                onSave={handleCodeEditorSave}
                onClose={() => setShowCodeEditor(false)}
              />
            )}
          </>
        )}
      </>
    </div>
  );
}

export default React.memo(EventNode);
