import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Dashboard.css";
import Dashboardheader from "./DashboardHeader";
import { useAuth } from "../../context/AuthContext";
import DataTable from "react-data-table-component";

const MonitorFlowContent = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { user, logout } = useAuth();
  const navigate = useNavigate();

 
  const columns = [
    {
      name: "SI No",
      selector: (row) => row.si_no,
      sortable: true,
      width: "80px",
    },
    {
      name: "Request ID",
      selector: (row) => row.request_id,
      sortable: true,
      grow: 2,
    },
    {
      name: "Request Time",
      selector: (row) => row.request_time,
      sortable: true,
      cell: (row) => (
        <span style={{ color: "#3366FF" }}>{row.request_time}</span>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        return (
          <>
            {entityData?.length > 0 ? (
              <>
                <span
                  style={{
                    backgroundColor:
                      row.status === "On" ? "#DCFCE7" : "#FEE2E2",
                    color: row.status === "On" ? "#16A34A" : "#DC2626",
                    padding: "4px 8px",
                    borderRadius: "4px",
                  }}
                >
                  {row.status}
                </span>
              </>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      name: "Error Message",
      selector: (row) => row.msg,
      sortable: true,
      grow: 2,
    },
  ];

  const getRandomRequestId = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '#';
    for (let i = 0; i < 10; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };
  
  const getRandomRequestTime = () => {
    const start = new Date(2024, 0, 1); // Start from Jan 1, 2024
    const end = new Date(2024, 11, 31); // End at Dec 31, 2024
    const randomDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    return randomDate.toLocaleString(); // Convert to a readable date-time string
  };
  
  const getRandomStatus = () => Math.random() < 0.5 ? "On" : "Off"; // Randomly return "On" or "Off"
  
  const getRandomMessage = () => {
    const messages = [
      "Check parameters and resubmit.",
      "Request processed successfully.",
      "Pending approval.",
      "Validation error, please check input.",
      "Request timed out, try again.",
      "System under maintenance.",
      "Action completed with warnings.",
      "Invalid credentials, access denied.",
      "Server is busy, try later.",
      "Re-submission needed."
    ];
    return messages[Math.floor(Math.random() * messages.length)];
  };
  
  const entityData = Array.from({ length: 20 }, (v, i) => ({
    id: i + 1,
    si_no: String(i + 1).padStart(2, "0"), // Pads single digits with a leading zero
    request_id: getRandomRequestId(), // Random request ID
    request_time: getRandomRequestTime(), // Random request time
    status: getRandomStatus(), // Random status (On/Off)
    msg: getRandomMessage() // Random message
  }));
  
  const customStyles = useMemo(() => {
    const baseStyles = {
      headRow: {
        style: {
          backgroundColor: "#F9FAFB",
          color: "#6B7280",
          marginBottom: "10px",
        },
      },
      headCells: {
        style: {
          borderRight: "1px solid #D1D1D1",
          padding: "12px 8px",
        },
      },
      rows: {
        style: {
          minHeight: "48px",
        },
      },
    };

    // Conditionally add cells style only if entityData is not empty
    if (entityData.length > 0) {
      baseStyles.cells = {
        style: {
          borderRight: "1px solid #D1D1D1",
          padding: "12px 8px",
        borderBottom: "1px solid #D1D1D1"
        },
      };
    }

    return baseStyles;
  }, [entityData]);

  return (
    <div className="dashboard-container">
      <Dashboardheader user={user} />
      <main className="main-content">
        <div className="flow-content-header">
          <div
            className="flow-content-home"
            onClick={() => navigate("/dashboard")}
          >
            <img
              src="/Home-icon.svg"
              alt="Home Icon"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <div className="flow-content-next">
            <img
              src="/next-icon.svg"
              alt="Dropdown Icon"
              className="flow-content-next-icon"
            />
          </div>
          <div className="content-monitor-flow" style={{ color: "#F8704A" }}>
            <span>Monitor Flow</span>
          </div>
          <div className="flow-content-next">
            <img
              src="/next-icon.svg"
              alt="Dropdown Icon"
              className="flow-content-next-icon"
            />
          </div>
          <div className="content-monitor-flow" style={{ color: "#777679" }}>
            <span>Login Flow</span>
          </div>
        </div>

        <div className="login-flow-container">
          <div className="login-flow-container-header">
            <div className="login-flow">
              <h2>Login Flow (100)</h2>
            </div>
            <div className="login-flow-search">
              <div className="relative-container-infra">
                <div className="search-icon">
                  <img src="/search-icon.svg" alt="Search Icon" />
                </div>
                <input
                  type="text"
                  placeholder="Search for anything..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-input"
                />
              </div>
            </div>
          </div>
          <div className="login-flow-table">
            <DataTable
              columns={columns}
              data={entityData.length > 0 ? entityData : []}
              customStyles={customStyles}
              pagination
              paginationPerPage={15}
              paginationRowsPerPageOptions={[15]}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default MonitorFlowContent;
