// src/components/EventHandlerNodeForm.jsx

import React, { useState, useCallback, useEffect } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { QueryBuilder } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";

const EventHandlerNodeForm = ({ node, onSubmit, entities }) => {
  const [formData, setFormData] = useState({
    name: node.data.name || "",
    description: node.data.description || "",
    logic_code: node.data.logic_code || "",
    inputFields: node.data.inputFields || [],
    outputFields: node.data.outputFields || [],
    entityReferences: node.data.entityReferences || [],
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    setFormData({
      name: node.data.name || "",
      description: node.data.description || "",
      logic_code: node.data.logic_code || "",
      inputFields: node.data.inputFields || [],
      outputFields: node.data.outputFields || [],
      entityReferences: node.data.entityReferences || [],
    });
  }, [node]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleAddField = useCallback((type) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${type}Fields`]: [
        ...prevData[`${type}Fields`],
        { id: Date.now(), name: "", type: "string" },
      ],
    }));
  }, []);

  const handleFieldChange = useCallback((type, id, key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${type}Fields`]: prevData[`${type}Fields`].map((field) =>
        field.id === id ? { ...field, [key]: value } : field
      ),
    }));
  }, []);

  const handleDeleteField = useCallback((type, id) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${type}Fields`]: prevData[`${type}Fields`].filter(
        (field) => field.id !== id
      ),
    }));
  }, []);

  const handleAddEntityReference = useCallback(() => {
    setFormData((prevData) => ({
      ...prevData,
      entityReferences: [
        ...prevData.entityReferences,
        { id: Date.now(), entity: "", query: { combinator: "and", rules: [] } },
      ],
    }));
  }, []);

  const handleEntityReferenceChange = useCallback((id, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      entityReferences: prevData.entityReferences.map((ref) =>
        ref.id === id ? { ...ref, [field]: value } : ref
      ),
    }));
  }, []);

  const handleDeleteEntityReference = useCallback((id) => {
    setFormData((prevData) => ({
      ...prevData,
      entityReferences: prevData.entityReferences.filter(
        (ref) => ref.id !== id
      ),
    }));
  }, []);

  const handleQueryChange = useCallback((id, query) => {
    setFormData((prevData) => ({
      ...prevData,
      entityReferences: prevData.entityReferences.map((ref) =>
        ref.id === id ? { ...ref, query } : ref
      ),
    }));
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);

      // Basic validation
      if (!formData.name.trim()) {
        setError("Event handler name is required");
        return;
      }

      if (
        formData.inputFields.some((field) => !field.name.trim()) ||
        formData.outputFields.some((field) => !field.name.trim())
      ) {
        setError("All fields must have a name");
        return;
      }

      onSubmit(node.id, formData).catch((err) => {
        setError(`Failed to update event handler: ${err.message}`);
      });
    },
    [node.id, formData, onSubmit]
  );

  const getEntityFields = useCallback(
    (entityId) => {
      const entity = entities.find((e) => e.id === entityId);
      if (!entity) return [];
      return entity.fields.map((field) => ({
        name: field.name,
        label: field.name,
        inputType: field.type,
      }));
    },
    [entities]
  );

  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <div style={{ color: "red", marginBottom: "10px" }}>{error}</div>
      )}
      <div>
        <label htmlFor="name">Handler Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor="description">Description:</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label htmlFor="logic_code">Logic Code:</label>
        <textarea
          id="logic_code"
          name="logic_code"
          value={formData.logic_code}
          onChange={handleInputChange}
          rows={10}
        />
      </div>
      <div>
        <h4>Input Fields:</h4>
        {formData.inputFields.map((field) => (
          <div key={field.id} style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={field.name}
              onChange={(e) =>
                handleFieldChange("input", field.id, "name", e.target.value)
              }
              placeholder="Field Name"
              required
            />
            <select
              value={field.type}
              onChange={(e) =>
                handleFieldChange("input", field.id, "type", e.target.value)
              }
            >
              <option value="string">String</option>
              <option value="number">Number</option>
              <option value="boolean">Boolean</option>
              <option value="file">File</option>
              <option value="object">Object</option>
              <option value="data">Date</option>
            </select>
            <FaTrash
              onClick={() => handleDeleteField("input", field.id)}
              style={{ cursor: "pointer", color: "red", marginLeft: "10px" }}
            />
          </div>
        ))}
        <button type="button" onClick={() => handleAddField("input")}>
          <FaPlus /> Add Input Field
        </button>
      </div>
      <div>
        <h4>Output Fields:</h4>
        {formData.outputFields.map((field) => (
          <div key={field.id} style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={field.name}
              onChange={(e) =>
                handleFieldChange("output", field.id, "name", e.target.value)
              }
              placeholder="Field Name"
              required
            />
            <select
              value={field.type}
              onChange={(e) =>
                handleFieldChange("output", field.id, "type", e.target.value)
              }
            >
              <option value="string">String</option>
              <option value="number">Number</option>
              <option value="boolean">Boolean</option>
              <option value="file">File</option>
              <option value="object">Object</option>
              <option value="data">Date</option>
            </select>
            <FaTrash
              onClick={() => handleDeleteField("output", field.id)}
              style={{ cursor: "pointer", color: "red", marginLeft: "10px" }}
            />
          </div>
        ))}
        <button type="button" onClick={() => handleAddField("output")}>
          <FaPlus /> Add Output Field
        </button>
      </div>
      <div>
        <h4>Entity References:</h4>
        {formData.entityReferences.map((ref) => (
          <div key={ref.id} style={{ marginBottom: "20px" }}>
            <select
              value={ref.entity}
              onChange={(e) =>
                handleEntityReferenceChange(ref.id, "entity", e.target.value)
              }
              required
            >
              <option value="">Select Entity</option>
              {entities.map((entity) => (
                <option key={entity.id} value={entity.id}>
                  {entity.name}
                </option>
              ))}
            </select>
            <FaTrash
              onClick={() => handleDeleteEntityReference(ref.id)}
              style={{ cursor: "pointer", color: "red", marginLeft: "10px" }}
            />
            {ref.entity && (
              <QueryBuilder
                fields={getEntityFields(ref.entity)}
                query={ref.query}
                onQueryChange={(q) => handleQueryChange(ref.id, q)}
              />
            )}
          </div>
        ))}
        <button type="button" onClick={handleAddEntityReference}>
          <FaPlus /> Add Entity Reference
        </button>
      </div>
      <button type="submit">Update Event Handler</button>
    </form>
  );
};

export default React.memo(EventHandlerNodeForm);
