// src/components/EventHandlerNode.jsx

import React, { useState, useCallback, useEffect } from "react";
import { Handle, Position } from "@xyflow/react";
import AceEditor from "react-ace";
import { QueryBuilder } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import {
  FaCode,
  FaWindowMaximize,
  FaWindowMinimize,
  FaTrash,
  FaPlus,
} from "react-icons/fa";
import AuthSettings from "./AuthSettings";

function CodeEditorPopup({ code, onSave, onClose }) {
  const [editorCode, setEditorCode] = useState(code);

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <AceEditor
          mode="javascript"
          theme="monokai"
          onChange={setEditorCode}
          value={editorCode}
          name="logic_code_editor"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            useWorker: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
          style={{ width: "100%", height: "200px" }}
        />
        <div className="popup-actions">
          <button onClick={() => onSave(editorCode)}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export function QueryEditorPopup({ query, onSave, onClose, fields }) {
  const [queryState, setQueryState] = useState(query);

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <QueryBuilder
          fields={fields}
          query={queryState}
          onQueryChange={(q) => setQueryState(q)}
        />
        <div className="popup-actions">
          <button onClick={() => onSave(queryState)}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

function EventHandlerNode({ data, entities, id, onUpdate, selected, users, roles }) {
  const [formData, setFormData] = useState({
    name: data.name || "",
    description: data.description || "",
    logic_code: data.logic_code || "",
    save_entity_id: data.save_entity_id || null,
    delete_entity_id: data.delete_entity_id || null,
  });
  const [inputFields, setInputFields] = useState(data.inputFields || []);
  const [outputFields, setOutputFields] = useState(data.outputFields || []);
  const [entityReferences, setEntityReferences] = useState(
    data.entityReferences || []
  );
  const [saveEntityId, setSaveEntityId] = useState(data.save_entity_id || null);
  const [deleteEntityId, setDeleteEntityId] = useState(
    data.delete_entity_id || null
  );

  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [showQueryEditor, setShowQueryEditor] = useState(false);
  const [currentFieldIndex, setCurrentFieldIndex] = useState(null);
  const [minimized, setMinimized] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    console.log(name, value, e.target);
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleCodeEditorSave = useCallback((newCode) => {
    setFormData((prev) => ({
      ...prev,
      logic_code: newCode,
    }));
    setShowCodeEditor(false);
  }, []);

  const handleQueryEditorSave = useCallback(
    (newQuery) => {
      setEntityReferences((prev) => {
        const newReferences = [...prev];
        newReferences[currentFieldIndex].query = newQuery;
        return newReferences;
      });
      setShowQueryEditor(false);
    },
    [currentFieldIndex]
  );

  const handleAddField = useCallback((type) => {
    const newField = { id: Date.now(), name: "", type: "string" };
    if (type === "input") {
      setInputFields((prev) => [...prev, newField]);
    } else {
      setOutputFields((prev) => [...prev, newField]);
    }
  }, []);

  const handleDeleteField = useCallback((type, id) => {
    if (type === "input") {
      setInputFields((prev) => prev.filter((field) => field.id !== id));
    } else {
      setOutputFields((prev) => prev.filter((field) => field.id !== id));
    }
  }, []);

  const handleFieldChange = useCallback((type, id, key, value) => {
    const setFields = type === "input" ? setInputFields : setOutputFields;
    setFields((prev) =>
      prev.map((field) =>
        field.id === id ? { ...field, [key]: value } : field
      )
    );
  }, []);

  const handleAddEntityReference = useCallback(() => {
    setEntityReferences((prev) => [
      ...prev,
      { id: Date.now(), entity: "", query: { combinator: "and", rules: [] } },
    ]);
  }, []);

  const handleDeleteEntityReference = useCallback((id) => {
    setEntityReferences((prev) => prev.filter((ref) => ref.id !== id));
  }, []);

  const handleEntityReferenceChange = useCallback((id, field, value) => {
    setEntityReferences((prev) => {
      return prev.map((ref) => {
        if (ref.id === id) {
          const updatedRef = { ...ref, [field]: value };
          // Reset the query when a new entity is selected
          if (field === "entity") {
            updatedRef.query = { combinator: "and", rules: [] };
          }
          return updatedRef;
        }
        return ref;
      });
    });
  }, []);

  const getEntityFields = useCallback(
    (entityId) => {
      const entity = entities.find((e) => e.id == entityId);
      if (!entity) return [];
      return entity.fields.map((field) => ({
        name: field.name,
        label: field.name,
        inputType: field.type,
      }));
    },
    [entities]
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onUpdate(id, {
        ...formData,
        inputFields,
        outputFields,
        entityReferences,
      }).catch((err) => {
        setError(`Failed to update event handler: ${err.message}`);
      });
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [formData, inputFields, outputFields, entityReferences, id, onUpdate]);

  return (
    <>
      <div
        className="event-handler-node"
        style={{
          border: selected ? "2px solid #666" : "0px solid black",
          padding: "5px",
          borderRadius: "5px",
        }}
      >
        <div className="node-header">
          {minimized ? (
            <FaWindowMaximize onClick={() => setMinimized(false)} />
          ) : (
            <FaWindowMinimize onClick={() => setMinimized(true)} />
          )}
          <FaCode /> {formData.name || "Event Handler"}
        </div>
        {error && (
          <div
            style={{
              backgroundColor: "#ffcccc",
              color: "red",
              padding: "5px",
              fontSize: "12px",
            }}
          >
            {error}
          </div>
        )}

        <>
          <div className="handles-container">
            <div className="input-handles">
              {inputFields.map((field) => (
                <div key={`${id}-input-${field.id}`} className="handle-wrapper">
                  <Handle
                    type="target"
                    position={Position.Left}
                    id={`${id}-input-${field.id}`}
                    style={{ left: 6 }}
                  />
                  <span className="handle-label">
                    {field.name} ({field.type})
                  </span>
                </div>
              ))}
            </div>
            <div className="output-handles">
              {outputFields.map((field) => (
                <div
                  key={`${id}-output-${field.id}`}
                  className="handle-wrapper"
                >
                  <span className="handle-label">
                    {field.name} ({field.type})
                  </span>
                  <Handle
                    type="source"
                    position={Position.Right}
                    id={`${id}-output-${field.id}`}
                    style={{ right: 6 }}
                  />
                </div>
              ))}
            </div>
          </div>
          {!minimized && (
            <div className="node-content">
              <form className="node-form">
                <div className="form-field">
                  <label htmlFor="name">Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Event Handler name"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="description">Description:</label>
                  <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Event Handler description"
                  />
                </div>
                <AuthSettings
                  data={data}
                  id={id}
                  onUpdate={onUpdate}
                  selected={selected}
                  setError={setError}
                  entities={entities}
                  users={users}
                  roles={roles}
                />
                <div className="form-field">
                  <label htmlFor="logic_code">Logic Code:</label>
                  <textarea
                    id="logic_code"
                    name="logic_code"
                    value={formData.logic_code}
                    onChange={handleInputChange}
                    placeholder="JavaScript code"
                    onClick={() => setShowCodeEditor(true)}
                    readOnly
                  />
                </div>
                <div className="form-field">
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Input Fields: </label>
                    <FaPlus
                      onClick={() => handleAddField("input")}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                  <div className="fields-list">
                    {inputFields.map((field) => (
                      <div
                        key={field.id}
                        className="field"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Field Name"
                          value={field.name}
                          onChange={(e) =>
                            handleFieldChange(
                              "input",
                              field.id,
                              "name",
                              e.target.value
                            )
                          }
                        />
                        <select
                          value={field.type}
                          onChange={(e) =>
                            handleFieldChange(
                              "input",
                              field.id,
                              "type",
                              e.target.value
                            )
                          }
                        >
                          <option value="string">String</option>
                          <option value="number">Number</option>
                          <option value="boolean">Boolean</option>
                          <option value="file">File</option>
                          <option value="object">Object</option>
                          <option value="date">Date</option>
                        </select>
                        <FaTrash
                          onClick={() => handleDeleteField("input", field.id)}
                          style={{ cursor: "pointer", color: "red" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="form-field">
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Output Fields:</label>
                    <FaPlus
                      onClick={() => handleAddField("output")}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                  <div className="fields-list">
                    {outputFields.map((field) => (
                      <div
                        key={field.id}
                        className="field"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Field Name"
                          value={field.name}
                          onChange={(e) =>
                            handleFieldChange(
                              "output",
                              field.id,
                              "name",
                              e.target.value
                            )
                          }
                        />
                        <select
                          value={field.type}
                          onChange={(e) =>
                            handleFieldChange(
                              "output",
                              field.id,
                              "type",
                              e.target.value
                            )
                          }
                        >
                          <option value="string">String</option>
                          <option value="number">Number</option>
                          <option value="boolean">Boolean</option>
                          <option value="file">File</option>
                          <option value="object">Object</option>
                          <option value="data">Date</option>
                        </select>
                        <FaTrash
                          onClick={() => handleDeleteField("output", field.id)}
                          style={{ cursor: "pointer", color: "red" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="form-field">
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Entity References:</label>
                    <FaPlus
                      onClick={handleAddEntityReference}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                  <div className="entity-references-list">
                    {entityReferences.map((ref) => (
                      <div
                        key={ref.id}
                        className="entity-reference"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <select
                          value={ref.entity}
                          onChange={(e) =>
                            handleEntityReferenceChange(
                              ref.id,
                              "entity",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Entity</option>
                          {entities.map((entity) => (
                            <option key={entity.id} value={entity.id}>
                              {entity.name}
                            </option>
                          ))}
                        </select>
                        <button
                          type="button"
                          onClick={() => {
                            setCurrentFieldIndex(
                              entityReferences.findIndex((r) => r.id === ref.id)
                            );
                            setShowQueryEditor(true);
                          }}
                        >
                          Edit Query
                        </button>
                        <FaTrash
                          onClick={() => handleDeleteEntityReference(ref.id)}
                          style={{ cursor: "pointer", color: "red" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="form-field">
                  <label htmlFor="save_entity_id">Save to Entity:</label>
                  <select
                    id="save_entity_id"
                    name="save_entity_id"
                    value={saveEntityId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Entity</option>
                    {entities.map((entity) => (
                      <option key={entity.id} value={entity.id}>
                        {entity.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-field">
                  <label htmlFor="delete_entity_id">Delete from Entity:</label>
                  <select
                    id="delete_entity_id"
                    name="save_entity_id"
                    value={deleteEntityId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Entity</option>
                    {entities.map((entity) => (
                      <option key={entity.id} value={entity.id}>
                        {entity.name}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
          )}
        </>
      </div>
      {showCodeEditor && (
        <CodeEditorPopup
          code={formData.logic_code}
          onSave={handleCodeEditorSave}
          onClose={() => setShowCodeEditor(false)}
        />
      )}
      {showQueryEditor && (
        <QueryEditorPopup
          query={entityReferences[currentFieldIndex].query}
          onSave={handleQueryEditorSave}
          onClose={() => setShowQueryEditor(false)}
          fields={getEntityFields(entityReferences[currentFieldIndex].entity)}
        />
      )}
    </>
  );
}

export default React.memo(EventHandlerNode);
