// src/components/AuthSettings.jsx

import React, { useState, useCallback, useEffect } from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import {
  FaPlus,
} from "react-icons/fa";
import { QueryEditorPopup } from "./EventHandlerNode";

function AuthSettingsComponent({ data, id, onUpdate, selected, entities, users, roles, setError }) {
  const authTypes = ["User", "Role", "Attribute", "Advanced"];
  const [authPreference, setAuthPreference] = useState(data.authPreference || null);
  const handleAddAuthPreference = useCallback(() => {
    setAuthPreference({ type: 'User', config: {} });
  }, []);
  const [showAuthQueryEditor, setShowAuthQueryEditor] = useState(false);
  const [selectedVegs, setSelectedVegs] = useState(['corn', 'tomato']);

  const handleAuthQueryEditorSave = useCallback(
    (newQuery) => {
      setAuthPreference({
        ...authPreference,
        config: { ...authPreference.config, query: newQuery },
      })
      setShowAuthQueryEditor(false);
    },
    [authPreference?.config?.entity]
  );
  const getEntityFieldsForAuth = useCallback(
    (entityId) => {
      const entity = entities.find((e) => e.id == entityId);
      if (!entity) return [];
      return entity.fields.map((field) => ({
        name: field.name,
        label: field.name,
        inputType: field.type,
      }));
    },
    [entities]
  );
  useEffect(() => {
    console.log("authPreference", authPreference);
    const timeoutId = setTimeout(() => {
      onUpdate(id, {
        ...data,
        authPreference
      }).catch((err) => {
        setError(`Failed to update event handler: ${err.message}`);
      });
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [authPreference, id, onUpdate]);

  return (
    <>
      <div
        className="auth-preference-node"
      >
        <div className="form-field">
          <span
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <label htmlFor="auth">Auth Preference:</label>
            <FaPlus
              onClick={handleAddAuthPreference}
              style={{ cursor: "pointer" }}
            />
          </span>
          <div className="fields-list">
            {authPreference && (
              <>
                <div className="field">
                  <label htmlFor="authType">Type:</label>
                  <select
                    id="authType"
                    value={authPreference.type}
                    onChange={(e) =>
                      setAuthPreference({ type: e.target.value, config: {} })
                    }
                  >
                    {authTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="field">
                  {authPreference.type === "User" && (
                    <>
                      <label htmlFor="userIds">Users:</label>
                      <select
                        id="userIds"
                        // multiple
                        value={authPreference.config.userIds || []}
                        onChange={(e) =>
                          setAuthPreference({
                            ...authPreference,
                            config: { userIds: Array.from(e.target.selectedOptions, (option) => option.value) },
                          })
                        }
                      >
                        {users.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.username}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  {authPreference.type === "Role" && (
                    <>
                      <label htmlFor="roleIds">Roles:</label>
                      {/* <select
                        multiple={true}
                        value={selectedVegs}
                        onChange={e => {
                          console.log(selectedVegs, e.target.selectedOptions);
                          const options = [...e.target.selectedOptions];
                          const values = options.map(option => option.value);
                          setSelectedVegs(values);
                          console.log(selectedVegs, e.target.selectedOptions);
                        }}
                      >
                        <option value="cucumber"  >Cucumber</option>
                        <option value="corn">Corn</option>
                        <option value="tomato">Tomato</option>
                      </select> */}
                      <select
                        name="roleIds"
                        id="roleIds"
                        multiple={true}
                        value={authPreference?.config?.roleIds || []}
                        onChange={(e) => {
                          console.log(e.target);
                          const selectedValues = Array.from(e.target.selectedOptions, (option) => option.value);
                          setAuthPreference({
                            ...authPreference,
                            config: { roleIds: selectedValues },
                          });
                        }
                        }
                      >
                        {roles.map((role) => (
                          <option key={role.id} value={role.id} onClick={e => {
                            const selectedValues = authPreference.config?.roleIds?.length ? authPreference.config?.roleIds.push(e.target.value) : [e.target.value];
                            console.log(selectedValues);
                            setAuthPreference({
                              ...authPreference,
                              config: { roleIds: selectedValues },
                            });
                          }
                          }
                          >
                            {role.name}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  {authPreference.type === "Attribute" && (
                    <>
                      <div className="entity-reference"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <label htmlFor="entity">Entity:</label>
                        <select
                          id="entity"
                          value={authPreference.config.entity || ""}
                          onChange={(e) =>
                            setAuthPreference({
                              ...authPreference,
                              config: { entity: e.target.value },
                            })
                          }
                        >
                          <option value="">Select Entity</option>
                          {entities.map((entity) => (
                            <option key={entity.id} value={entity.id}>
                              {entity.name}
                            </option>
                          ))}
                        </select>
                        <button
                          type="button"
                          onClick={() => {
                            setShowAuthQueryEditor(true);
                          }}
                        >
                          Edit Query
                        </button>
                      </div>
                    </>
                  )}
                  {authPreference.type === "Advanced" && (
                    <>
                      <label htmlFor="code">Code:</label>
                      <AceEditor
                        mode="javascript"
                        theme="monokai"
                        value={authPreference.config.code || ""}
                        onChange={(newCode) =>
                          setAuthPreference({
                            ...authPreference,
                            config: { code: newCode },
                          })
                        }
                        name="code-editor"
                        editorProps={{ $blockScrolling: true }}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {showAuthQueryEditor && (console.log("showAuthQueryEditor", authPreference?.config?.entity),
        <QueryEditorPopup
          query={authPreference?.config?.query}
          onSave={handleAuthQueryEditorSave}
          onClose={() => setShowAuthQueryEditor(false)}
          fields={getEntityFieldsForAuth(authPreference?.config?.entity)}
        />
      )}
    </>
  );
}

export default React.memo(AuthSettingsComponent);
