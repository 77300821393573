import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./Dashboard.css";
import { FaPen } from "react-icons/fa";

export const MonitorFlow = ({ filteredFlows }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;

  const totalPages = Math.ceil(filteredFlows.length / itemsPerPage);

  const currentFlows = filteredFlows.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );

  const navigate = useNavigate();
  return (
    <div className="dashboard-monitor-flow">
      <div className="monitor-flow-header">
        <div className="monitor-flow">
          <h1>MONITOR FLOW ({filteredFlows.length})</h1>
        </div>
        <button
          className="create-flow-button"
          onClick={() => navigate("/editor")}
        >
          <div style={{ gap: "6px", flexDirection: "row", display: "flex" }}>
            <div style={{ justifyContent: "center", alignContent: "center" }}>
              <img
                src="/add-icon.svg"
                alt="Add Icon"
                style={{
                  justifyContent: "center",
                  alignContent: "center",
                  width: "18px",
                  height: "18px",
                }}
              />
            </div>
            <div className="create-flow">
              <span>Create Flow</span>
            </div>
          </div>
        </button>
      </div>
      {filteredFlows.length > 0 ? (
        <div className="search-bar">
          <div className="relative-container">
            <div className="search-icon">
              <img src="/search-icon.svg" alt="Search Icon" />
            </div>
            <input
              type="text"
              placeholder="Search for anything..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>

          <div className="filter-options">
            <label>
              <input type="radio" name="filter" value="all" defaultChecked />
              <span>All</span>
            </label>
            <label>
              <input type="radio" name="filter" value="failed" />
              <span> Failed</span>
            </label>
            <label>
              <input type="radio" name="filter" value="complete" />
              <span> Complete</span>
            </label>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={"flows-grid"}>
        {totalPages > 1 && (
          <div style={{ justifyContent: "center", alignContent: "center" }}>
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
              disabled={currentPage === 0}
              className="carousel-button"
            >
              <img
                src="/dropdown-icon.svg"
                alt="DropDown"
                style={{
                  transform: "rotate(90deg)",
                  marginTop: "4px",
                  width: "28px",
                  height: "28px",
                }}
              />
            </button>
          </div>
        )}
        {/* {filteredFlows.length > 0 ? ( */}
        {currentFlows.length > 0 ? (
          currentFlows.map((flow) => {
            const progress1 = Math.floor(Math.random() * 10)+90;
            const progress2 = 100 - progress1;

            const sumProgress = progress1 + progress2;

            const borderColor = progress2 > 10 ? "#D70F0F" : "#4CAF50";
            const backgroundColor = progress2 > 10 ? "#FFF4F4" : "#F0FFF0";

            return (
              <div
                key={flow.id}
                className="flow-card"
                style={{
                  border: `3px solid ${borderColor}`,
                  backgroundColor: `${backgroundColor}`,
                }}
                onClick={() => navigate(`/flow-status?id=${flow.id}`)}
              >
                <div className="flow-header">
                  <h3 style={{marginRight: 40}}>{flow.name || flow.id}</h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                    }}
                  >
                    <img
                      src="/clock.svg"
                      alt="Clock"
                      style={{
                        marginRight: 5,
                      }}
                    />
                    <div style={{ fontSize: "12px", color: "#888", flex: 1 }}>
                      Edited on {flow.updated_at}
                    </div>
                  </div>
                  <FaPen
                      style={{ cursor: "pointer",zIndex: 999, flex: 1, maxWidth: 30 }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        navigate(`/editor?id=${flow.id}`);
                      }}
                    />
                </div>
                <img
                  src="/job.png"
                  alt="Flow preview"
                  className="flow-preview"
                />
                <div className="flow-stats">
                  <span>Flow Request</span>
                  <span className="flow-count">{sumProgress}</span>
                </div>
                <div className="progress-bar">
                  <div className="progress1" style={{ width: `${progress1}%` }}>
                    <div>
                      <span>{`${progress1}`}</span>
                    </div>
                  </div>
                  {progress2 > 0 && (
                    <div
                      className="progress2"
                      style={{ width: `${progress2}%` }}
                    >
                      <div>
                        <span>{`${progress2}`}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="flow-default">
            <img src="/image1.png" alt="" className="flow-image" />
          </div>
        )}
        {totalPages > 1 && (
          // <div className="pagination">
          <div style={{ justifyContent: "center", alignContent: "center" }}>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))
              }
              disabled={currentPage === totalPages - 1}
              className="carousel-button"
            >
              <img
                src="/dropdown-icon.svg"
                alt="DropDown"
                style={{
                  transform: "rotate(-90deg)",
                  marginTop: "4px",
                  width: "28px",
                  height: "28px",
                }}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
