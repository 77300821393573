import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import "./Dashboard.css";

const Dashboardheader=({user})=>{
    const [isRotated, setIsRotated] = useState(false);
    const location = useLocation();
    const title=location.pathname.slice(1);
    const handleIconClick = () => {
        setIsRotated((prevState) => !prevState); // Toggle rotation on click
      };
    
      const headerTitle=()=>{
        switch (title) {
            case 'dashboard':
              return 'Dashboard'
            case 'flow-status':
             return 'Monitor Flow'
            default:
              return 'Dashboard'
          }
      }
      
    return (
        <header className="header">
        <div className="logo">
          <img src="/logo.svg" alt="Logo" />
          <span>{headerTitle()}</span>
        </div>
        <div className="icon-header">
          <div className="bell-icon">
            <img
              src="/bell-icon.svg"
              className="header-icon-size"
              alt="Bell-icon"
            />
          </div>
          <div className="setting-icon">
            <img
              src="/settings.svg"
              className="header-icon-size"
              alt="Bell-icon"
            />
          </div>
          <div className="user-info">
            <img
              src={user?.avatar || "/defaultuser.png"}
              alt="User avatar"
              className="avatar"
            />
            <div className="user-name">
              <div className="user-name-sub1">
                <span>
                {(user?.name?.trim().split(' ')[0] || user?.preferred_username?.trim().split(' ')[0] || "User")}
                </span>
                <h1>Admin</h1>
              </div>
              <div className="dropdown-div" onClick={handleIconClick}>
                <img
                  src="/dropdown-icon.svg"
                  alt="DropDown"
                  className={`Dropdown-icon ${isRotated ? "rotated" : ""}`}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    )

}

export default Dashboardheader;
