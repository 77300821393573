import React, { useState, useCallback, useEffect } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { QueryBuilder } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';

const ConditionNodeForm = ({ node, onSubmit, entities }) => {
  const [formData, setFormData] = useState({
    name: node.data.name || 'Condition',
    description: node.data.description || '',
    conditions: node.data.conditions || [
      { id: 'if', query: { combinator: 'and', rules: [] } },
      { id: 'else', query: { combinator: 'and', rules: [] } },
    ],
    inputFields: node.data.inputFields || [],
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    setFormData({
      name: node.data.name || 'Condition',
      description: node.data.description || '',
      conditions: node.data.conditions || [
        { id: 'if', query: { combinator: 'and', rules: [] } },
        { id: 'else', query: { combinator: 'and', rules: [] } },
      ],
      inputFields: node.data.inputFields || [],
    });
  }, [node]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }, []);

  const handleAddCondition = useCallback(() => {
    setFormData(prevData => ({
      ...prevData,
      conditions: [
        ...prevData.conditions,
        { id: `elseif_${Date.now()}`, query: { combinator: 'and', rules: [] } },
      ],
    }));
  }, []);

  const handleDeleteCondition = useCallback((conditionId) => {
    setFormData(prevData => ({
      ...prevData,
      conditions: prevData.conditions.filter(c => c.id !== conditionId),
    }));
  }, []);

  const handleQueryChange = useCallback((conditionId, query) => {
    setFormData(prevData => ({
      ...prevData,
      conditions: prevData.conditions.map(c =>
        c.id === conditionId ? { ...c, query } : c
      ),
    }));
  }, []);

  const handleAddInputField = useCallback(() => {
    setFormData(prevData => ({
      ...prevData,
      inputFields: [
        ...prevData.inputFields,
        { id: Date.now(), name: '', type: 'string' },
      ],
    }));
  }, []);

  const handleDeleteInputField = useCallback((fieldId) => {
    setFormData(prevData => ({
      ...prevData,
      inputFields: prevData.inputFields.filter(field => field.id !== fieldId),
    }));
  }, []);

  const handleInputFieldChange = useCallback((fieldId, key, value) => {
    setFormData(prevData => ({
      ...prevData,
      inputFields: prevData.inputFields.map(field =>
        field.id === fieldId ? { ...field, [key]: value } : field
      ),
    }));
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setError(null);

    // Basic validation
    if (!formData.name.trim()) {
      setError('Condition name is required');
      return;
    }

    if (formData.inputFields.some(field => !field.name.trim())) {
      setError('All input fields must have a name');
      return;
    }

    onSubmit(node.id, formData).catch(err => {
      setError(`Failed to update condition: ${err.message}`);
    });
  }, [node.id, formData, onSubmit]);

  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <div style={{ color: 'red', marginBottom: '10px' }}>
          {error}
        </div>
      )}
      <div>
        <label htmlFor="name">Condition Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor="description">Description:</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <h4>Input Fields:</h4>
        {formData.inputFields.map((field) => (
          <div key={field.id} style={{ marginBottom: '10px' }}>
            <input
              type="text"
              value={field.name}
              onChange={(e) => handleInputFieldChange(field.id, 'name', e.target.value)}
              placeholder="Field Name"
              required
            />
            <select
              value={field.type}
              onChange={(e) => handleInputFieldChange(field.id, 'type', e.target.value)}
            >
              <option value="string">String</option>
              <option value="number">Number</option>
              <option value="boolean">Boolean</option>
              <option value="date">Date</option>
            </select>
            <FaTrash
              onClick={() => handleDeleteInputField(field.id)}
              style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
            />
          </div>
        ))}
        <button type="button" onClick={handleAddInputField}>
          <FaPlus /> Add Input Field
        </button>
      </div>
      <div>
        <h4>Conditions:</h4>
        {formData.conditions.map((condition) => (
          <div key={condition.id} style={{ marginBottom: '20px' }}>
            <h5>
              {condition.id === 'if'
                ? 'If'
                : condition.id === 'else'
                ? 'Else'
                : 'Else If'}
            </h5>
            {condition.id !== 'else' && (
              <QueryBuilder
                fields={formData.inputFields.map(field => ({
                  name: field.name,
                  label: field.name,
                  inputType: field.type,
                }))}
                query={condition.query}
                onQueryChange={(q) => handleQueryChange(condition.id, q)}
              />
            )}
            {condition.id !== 'if' && condition.id !== 'else' && (
              <FaTrash
                onClick={() => handleDeleteCondition(condition.id)}
                style={{ cursor: 'pointer', color: 'red', marginTop: '5px' }}
              />
            )}
          </div>
        ))}
        <button type="button" onClick={handleAddCondition}>
          <FaPlus /> Add Else If Condition
        </button>
      </div>
      <button type="submit">Update Condition</button>
    </form>
  );
};

export default React.memo(ConditionNodeForm);