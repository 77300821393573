// src/components/Sidebar.jsx

import React, { useCallback, useState } from 'react';
import { FaDatabase, FaBolt as FaThunderbolt, FaCode, FaCogs, FaCloud, FaFile, FaMagic, FaPlus, FaRandom } from 'react-icons/fa';
import EventNodeForm from './EventNodeForm';
import EventHandlerNodeForm from './EventHandlerNodeForm';
import PluginNodeForm from './PluginNodeForm';
import PluginRegistrationForm from './PluginRegistrationForm';
import ConditionNodeForm from './ConditionNodeForm';
import TriggerRegistrationForm from './TriggerRegistrationForm';

const Sidebar = ({ selectedElement, plugins, onUpdateNode, entities, onPluginRegistered, triggers = [], onTriggerRegistered }) => {
  const [error, setError] = useState(null);
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const [isTriggerRegistrationModalOpen, setIsTriggerRegistrationModalOpen] = useState(false);

  const onDragStart = useCallback((event, nodeType, pluginName = null) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    if (pluginName) {
      event.dataTransfer.setData(
        nodeType === 'pluginNode' ? 'application/pluginName' :
        'application/triggerName', pluginName);
    }
    event.dataTransfer.effectAllowed = 'move';
  }, []);

  const sidebarItemStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80px',
    height: '80px',
    margin: '5px',
    backgroundColor: '#f0f0f0',
    border: '1px solid #ddd',
    borderRadius: '5px',
    cursor: 'grab',
    flexDirection: 'column',
  };

  const iconStyle = {
    fontSize: '32px',
    marginBottom: '5px',
  };

  const handleFormSubmit = useCallback((nodeId, newData) => {
    setError(null);
    onUpdateNode(nodeId, newData)
      .catch(err => {
        setError(`Failed to update node: ${err.message}`);
      });
  }, [onUpdateNode]);

  const renderSelectedElementForm = useCallback(() => {
    if (!selectedElement) return null;

    const commonProps = {
      node: selectedElement,
      onSubmit: handleFormSubmit,
      entities: entities,
    };

    switch (selectedElement.type) {
      case 'eventNode':
        return <EventNodeForm {...commonProps} />;
      case 'eventHandlerNode':
        return <EventHandlerNodeForm {...commonProps} />;
      case 'pluginNode':
        return <PluginNodeForm {...commonProps} />;
      case 'conditionNode':
        return <ConditionNodeForm {...commonProps} />;
      default:
        return <p>Unsupported node type: {selectedElement.type}</p>;
    }
  }, [selectedElement, handleFormSubmit, entities]);

  const handlePluginRegistration = async (pluginData) => {
    try {
      await onPluginRegistered(pluginData);
      setIsRegistrationModalOpen(false);
      setError(null);
    } catch (err) {
      setError(`Failed to register plugin: ${err.message}`);
    }
  };

  const handleTriggerRegistration = async (triggerData) => {
    try {
      await onTriggerRegistered(triggerData);
      setIsTriggerRegistrationModalOpen(false);
      setError(null);
    } catch (err) {
      setError(`Failed to register trigger: ${err.message}`);
    }
  }

  return (
    <aside style={{
      padding: '15px',
      borderRight: '1px solid #ddd',
      backgroundColor: '#f8f8f8',
      height: 'calc(100vh - 126px)',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    }}>
      {error && (
        <div style={{ backgroundColor: "#ffcccc", color: "red", padding: "10px", marginBottom: "10px" }}>
          {error}
        </div>
      )}
      <div style={{ marginBottom: '20px' }}>
        <h3>Elements</h3>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div
            style={sidebarItemStyle}
            onDragStart={(event) => onDragStart(event, 'eventNode')}
            draggable
            title="Event Node"
          >
            <FaThunderbolt style={iconStyle} />
            <span style={{fontSize: 10, textAlign: 'center'}}>Event</span>
          </div>
          <div
            style={sidebarItemStyle}
            onDragStart={(event) => onDragStart(event, 'eventHandlerNode')}
            draggable
            title="Event Handler Node"
          >
            <FaCode style={iconStyle} />
            <span style={{fontSize: 10, textAlign: 'center'}}>Event Handler</span>
          </div>
          <div
            style={sidebarItemStyle}
            onDragStart={(event) => onDragStart(event, 'conditionNode')}
            draggable
            title="Condition Node"
          >
            <FaRandom style={iconStyle} />
            <span style={{fontSize: 10, textAlign: 'center'}}>Condition</span>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <h3>Plugins</h3>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {plugins.map((plugin) => (
            <div
              key={plugin.id}
              style={sidebarItemStyle}
              onDragStart={(event) => onDragStart(event, 'pluginNode', plugin.name)}
              draggable
              title={plugin.name}
            >
              {plugin.icon === 'database' && <FaDatabase style={iconStyle} />}
              {plugin.icon === 'cloud' && <FaCloud style={iconStyle} />}
              {plugin.icon === 'file' && <FaFile style={iconStyle} />}
              {plugin.icon === 'intelligence' && <FaMagic style={iconStyle} />}
              {!['database', 'cloud', 'file', 'intelligence'].includes(plugin.icon) && <FaCogs style={iconStyle} />}
              <span style={{fontSize: 10, textAlign: 'center'}}>{plugin.name}</span>
            </div>
          ))}
          <div
            style={{...sidebarItemStyle, cursor: 'pointer'}}
            onClick={() => setIsRegistrationModalOpen(true)}
            title="Register New Plugin"
          >
            <FaPlus style={iconStyle} />
            <span style={{fontSize: 10, textAlign: 'center'}}>Add Plugin</span>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <h3>Triggers</h3>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {triggers.map((trigger) => (
            <div
              key={trigger.id}
              style={sidebarItemStyle}
              onDragStart={(event) => onDragStart(event, 'triggerNode', trigger.name)}
              draggable
              title={trigger.name}
            >
              {trigger.icon === 'database' && <FaDatabase style={iconStyle} />}
              {trigger.icon === 'cloud' && <FaCloud style={iconStyle} />}
              {trigger.icon === 'file' && <FaFile style={iconStyle} />}
              {trigger.icon === 'intelligence' && <FaMagic style={iconStyle} />}
              {!['database', 'cloud', 'file', 'intelligence'].includes(trigger.icon) && <FaCogs style={iconStyle} />}
              <span style={{fontSize: 10, textAlign: 'center'}}>{trigger.name}</span>
            </div>
          ))}
          <div
            style={{...sidebarItemStyle, cursor: 'pointer'}}
            onClick={() => setIsTriggerRegistrationModalOpen(true)}
            title="Register New Trigger"
          >
            <FaPlus style={iconStyle} />
            <span style={{fontSize: 10, textAlign: 'center'}}>Add Trigger</span>
          </div>
        </div>
      </div>

      {/* {selectedElement && (
        <div style={{ marginTop: '20px' }}>
          <h3>Selected Element: {selectedElement.data.name || selectedElement.data.label}</h3>
          {renderSelectedElementForm()}
        </div>
      )} */}

      {isRegistrationModalOpen && (
        <PluginRegistrationForm
          onSubmit={handlePluginRegistration}
          onClose={() => setIsRegistrationModalOpen(false)}
        />
      )}
      {isTriggerRegistrationModalOpen && (
        <TriggerRegistrationForm
          onSubmit={handleTriggerRegistration}
          onClose={() => setIsTriggerRegistrationModalOpen(false)}
        />
      )}
    </aside>
  );
};

export default React.memo(Sidebar);
