import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { Bar, Line, Pie, Doughnut, Radar, PolarArea } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
  RadialLinearScale,
} from "chart.js";

import { customApi, entityApi } from "../services/api";
import ChatComponent from "./Copilot";
import { Header } from "./Header";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
  RadialLinearScale
);

const ChartComponent = ({ query, chartConfig, type, code }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await customApi.query(query);
        const response = code ? eval(code)(result.data) : result;
        setData({
          labels: response.labels,
          datasets: [
            {
              label: chartConfig.title,
              data: response.values,
              backgroundColor: chartConfig.backgroundColor,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [query]);
  const Chart = useCallback(() => {
    if (!data || !data.labels) return <div>Loading...</div>;

    switch ((type || "bar").toLowerCase()) {
      case "bar":
        return <Bar data={data} {...(chartConfig.charts || {})} />;
      case "line":
        return <Line data={data} {...(chartConfig.charts || {})} />;
      case "pie":
        return <Pie data={data} {...(chartConfig.charts || {})} />;
      case "doughnut":
        return <Doughnut data={data} {...(chartConfig.charts || {})} />;
      case "radar":
        return <Radar data={data} {...(chartConfig.charts || {})} />;
      case "polar":
        return <PolarArea data={data} {...(chartConfig.charts || {})} />;
      case "table":
        return (
          <table striped bordered hover>
            <thead>
              <tr>
                {data.labels.map((label, index) => (
                  <th key={index}>{label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.datasets[0].data.map((value, index) => (
                <tr key={index}>
                  {Object.values(value).map((val, i) => (
                    <td key={i}>{val}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      default:
        return <div>Unsupported chart type</div>;
    }
  }, [data, code]);

  //   const chartData = {
  //     labels: data.labels,
  //     datasets: [
  //       {
  //         label: chartConfig.title,
  //         data: data.values,
  //         backgroundColor: chartConfig.backgroundColor,
  //       },
  //     ],
  //   };
  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {chartConfig.title && <h3>{chartConfig.title}</h3>}
      {Chart()}
    </div>
  );
};

ChartComponent.propTypes = {
  query: PropTypes.string.isRequired,
  chartConfig: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

const DashboardPage = () => {
  const [entities, setEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    // Fetch entities from backend
    entityApi
      .getAll()
      .then((response) => {
        setEntities(response.data);
      })
      .catch((error) => {
        console.error("Error fetching entities:", error);
      });
  }, []);

  const handleEntitySelect = (entity) => {
    console.log(
      "Selected entity:",
      entity,
      selectedEntity,
      entities.find((e) => e.id == entity)
    );

    setSelectedEntity(entities.find((e) => e.id == entity));
    setDashboardData([]);
  };

  const handleSendMessage = async (message) => {
    // Send message to chat backend (using Ollama with function calling)
    if (!selectedEntity) {
      alert("Please select an entity to chat with");
      return null;
    }
    return customApi
      .chat(message, selectedEntity.id)
      .then((response) => {
        const { tool_calls, text } = response.data;
        console.log("Chat response:", text);
        tool_calls.forEach((result) => {
          if (result.function) {
            // Update dashboard data with the JSON structure from the chat response
            setDashboardData((prevData) => [
              ...prevData,
              result.function.arguments,
            ]);
          }
        });
        return { role: "assistant", ...response.data };
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };

  return (
    <div>
      <Header />

      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <div>
            <select
              id="dropdown-basic-button"
              title={selectedEntity?.name || "Select Entity"}
              onChange={(e) => handleEntitySelect(e.target.value)}
            >
              {entities.map((entity, index) => (
                <option key={index} value={entity.id}>
                  {entity.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {dashboardData.map((data, index) => (
            <div
              key={index}
              style={{
                minWidth: "30vw",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                margin: 10,
                padding: 10,
                backgroundColor: "#FFF",
                margin: 10,
                padding: 10,
                borderRadius: 10,
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <ChartComponent
                query={data.query}
                chartConfig={data.chartConfig}
                type={data.type}
                code={data.code}
              />
            </div>
          ))}
        </div>
        <ChatComponent onSendMessage={handleSendMessage} />
      </div>
    </div>
  );
};

export default DashboardPage;

// Example usage
// <DashboardPage />;
