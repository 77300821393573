// src/components/PluginRegistrationForm.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaTrash, FaCode } from 'react-icons/fa';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";

function CodeEditorPopup({ code, onSave, onClose, title }) {
  const [editorCode, setEditorCode] = useState(code);

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h3>{title}</h3>
        <AceEditor
          mode="javascript"
          theme="monokai"
          onChange={setEditorCode}
          value={editorCode}
          name="code_editor"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            useWorker: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
          style={{ width: "100%", height: "300px" }}
        />
        <div className="popup-actions">
          <button onClick={() => onSave(editorCode)}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

CodeEditorPopup.propTypes = {
  code: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const PluginRegistrationForm = ({ onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    api_endpoint: '',
    input_mapping: [{ id: Date.now(), name: '', dataType: 'string', required: false, validation: '' }],
    output_mapping: [{ id: Date.now() + 1, name: '', dataType: 'string', required: false, validation: '' }],
    synchronous: false,
    async_check_api: '',
    async_check_interval: 1000,
    plugin_call_code: '',
    plugin_check_api_code: '',
  });
  const [error, setError] = useState('');
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [currentEditingField, setCurrentEditingField] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleMappingChange = (type, id, field, value) => {
    setFormData(prevState => ({
      ...prevState,
      [type]: prevState[type].map(item => 
        item.id === id ? { ...item, [field]: value } : item
      )
    }));
  };

  const addMapping = (type) => {
    setFormData(prevState => ({
      ...prevState,
      [type]: [...prevState[type], { id: Date.now(), name: '', dataType: 'string', required: false, validation: '' }]
    }));
  };

  const removeMapping = (type, id) => {
    setFormData(prevState => ({
      ...prevState,
      [type]: prevState[type].filter(item => item.id !== id)
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    // Client-side validation
    if (formData.name.trim().length === 0) {
      setError('Name is required and must not be empty');
      return;
    }

    if (formData.input_mapping.some(m => !m.name) || formData.output_mapping.some(m => !m.name)) {
      setError('All mapping names are required');
      return;
    }

    // Prepare data for submission
    const submissionData = {
      ...formData,
      input_mapping: formData.input_mapping.map(({ id, ...rest }) => rest),
      output_mapping: formData.output_mapping.map(({ id, ...rest }) => rest),
    };

    onSubmit(submissionData);
  };

  const renderMappingFields = (type) => {
    return formData[type].map((mapping) => (
      <div key={mapping.id} style={{ marginBottom: '10px', border: '1px solid #ccc', padding: '10px', display: 'flex', flexDirection: 'row' }}>
        <input
          type="text"
          placeholder="Name"
          value={mapping.name}
          onChange={(e) => handleMappingChange(type, mapping.id, 'name', e.target.value)}
          style={{ width: '100%', marginBottom: '5px', marginRight: '5px' }}
          required
        />
        <select
          value={mapping.dataType}
          onChange={(e) => handleMappingChange(type, mapping.id, 'dataType', e.target.value)}
          style={{ width: '100%', marginBottom: '5px', marginRight: '5px' }}
          required
        >
          <option value="string">String</option>
          <option value="number">Number</option>
          <option value="boolean">Boolean</option>
          <option value="file">File</option>
          <option value="object">Object</option>
          <option value="data">Date</option>
        </select>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', marginRight: '5px' }}>
          <input
            type="checkbox"
            id={`required-${mapping.id}`}
            checked={mapping.required}
            onChange={(e) => handleMappingChange(type, mapping.id, 'required', e.target.checked)}
            style={{ marginRight: '5px' }}
          />
          <label htmlFor={`required-${mapping.id}`}>Required</label>
        </div>
        <input
          type="text"
          placeholder="Validation (optional)"
          value={mapping.validation}
          onChange={(e) => handleMappingChange(type, mapping.id, 'validation', e.target.value)}
          style={{ width: '100%', marginBottom: '5px', marginRight: '5px' }}
        />
        <button type="button" onClick={() => removeMapping(type, mapping.id)}><FaTrash /></button>
      </div>
    ));
  };

  const handleOpenCodeEditor = (field) => {
    setCurrentEditingField(field);
    setShowCodeEditor(true);
  };

  const handleCodeEditorSave = (newCode) => {
    setFormData(prevState => ({
      ...prevState,
      [currentEditingField]: newCode
    }));
    setShowCodeEditor(false);
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        width: '600px',
        maxHeight: '80vh',
        overflowY: 'auto',
      }}>
        <h2>Register New Plugin</h2>
        {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              style={{ width: '100%', padding: '5px' }}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              style={{ width: '100%', padding: '5px', minHeight: '60px' }}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="api_endpoint">API Endpoint:</label>
            <input
              type="text"
              id="api_endpoint"
              name="api_endpoint"
              value={formData.api_endpoint}
              onChange={handleChange}
              required
              style={{ width: '100%', padding: '5px' }}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <h3>Input Mapping</h3>
            {renderMappingFields('input_mapping')}
            <button type="button" onClick={() => addMapping('input_mapping')}>Add Input Mapping</button>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <h3>Output Mapping</h3>
            {renderMappingFields('output_mapping')}
            <button type="button" onClick={() => addMapping('output_mapping')}>Add Output Mapping</button>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="synchronous">
              <input
                type="checkbox"
                id="synchronous"
                name="synchronous"
                checked={formData.synchronous}
                onChange={handleChange}
                style={{ marginRight: '5px' }}
              />
              Synchronous
            </label>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="async_check_api">Async Check API:</label>
            <input
              type="text"
              id="async_check_api"
              name="async_check_api"
              value={formData.async_check_api}
              onChange={handleChange}
              style={{ width: '100%', padding: '5px' }}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="async_check_interval">Async Check Interval (ms):</label>
            <input
              type="number"
              id="async_check_interval"
              name="async_check_interval"
              value={formData.async_check_interval}
              onChange={handleChange}
              style={{ width: '100%', padding: '5px' }}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="plugin_call_code">Plugin Call Code:</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button type="button" onClick={() => handleOpenCodeEditor('plugin_call_code')} style={{ marginRight: '10px' }}>
                <FaCode /> Edit Code
              </button>
              <span>{formData.plugin_call_code ? 'Code added' : 'No code added'}</span>
            </div>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="plugin_check_api_code">Plugin Check API Code:</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button type="button" onClick={() => handleOpenCodeEditor('plugin_check_api_code')} style={{ marginRight: '10px' }}>
                <FaCode /> Edit Code
              </button>
              <span>{formData.plugin_check_api_code ? 'Code added' : 'No code added'}</span>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button type="submit" style={{ padding: '5px 10px' }}>Register Plugin</button>
            <button type="button" onClick={onClose} style={{ padding: '5px 10px' }}>Cancel</button>
          </div>
        </form>
      </div>
      {showCodeEditor && (
        <CodeEditorPopup
          code={formData[currentEditingField]}
          onSave={handleCodeEditorSave}
          onClose={() => setShowCodeEditor(false)}
          title={currentEditingField === 'plugin_call_code' ? 'Plugin Call Code' : 'Plugin Check API Code'}
        />
      )}
    </div>
  );
};

PluginRegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PluginRegistrationForm;