// src/components/PluginNode.jsx

import React, { useState, useCallback, useEffect } from "react";
import { Handle, Position } from "@xyflow/react";
import {
  FaBolt,
  FaDatabase,
  FaFile,
  FaNetworkWired,
  FaWindowMaximize,
  FaWindowMinimize,
} from "react-icons/fa";
import AuthSettings from "./AuthSettings";

const IconMap = {
  database: <FaDatabase />,
  thunderbolt: <FaBolt />,
  file: <FaFile />,
};

function PluginNode({ data, id, onUpdate, selected, entities, users, roles }) {
  const [formData, setFormData] = useState(() => {
    const initialData = {};
    (data.inputFields || []).forEach(
      ({ name, defaultValue = "", dataType, options }) => {
        initialData[name] =
          (data?.formData ? data?.formData[name] : null) ||
          defaultValue ||
          (dataType === "boolean" ? false : dataType === "number" ? 0 : "");
      }
    );
    return initialData;
  });
  const [minimized, setMinimized] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  }, []);

  const handleFileChange = useCallback((e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onUpdate(id, { ...data, formData }).catch((err) => {
        setError(`Failed to update plugin: ${err.message}`);
      });
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [formData, id, data, onUpdate]);

  return (
    <div
      className="plugin-node"
      style={{
        border: selected ? "2px solid #666" : "0px solid black",
        padding: "5px",
        borderRadius: "5px",
      }}
    >
      <div className="node-header">
        {minimized ? (
          <FaWindowMaximize onClick={() => setMinimized(false)} />
        ) : (
          <FaWindowMinimize onClick={() => setMinimized(true)} />
        )}{" "}
        {IconMap[data.icon] || <FaNetworkWired />} {data.name || "Plugin"}
      </div>
      {error && (
        <div
          style={{
            backgroundColor: "#ffcccc",
            color: "red",
            padding: "5px",
            fontSize: "12px",
          }}
        >
          {error}
        </div>
      )}

      <div className="node-content">
      <AuthSettings
            data={data}
            id={id}
            onUpdate={onUpdate}
            selected={selected}
            entities={entities}
            users={users}
            roles={roles}
            setError={setError}
          />
        <div className="handles-container">
          
          <div className="input-handles">
            {data.inputFields?.map((input, index) => (
              <div
                key={`${id}-input-${input.id || index}`}
                className="handle-wrapper"
              >
                <Handle
                  type="target"
                  position={Position.Left}
                  id={`${id}-input-${input.id || index}`}
                />
                <span className="handle-label">
                  {input.name} ({input.dataType})
                </span>
              </div>
            ))}
          </div>

          <div className="output-handles">
            {data.outputFields?.map((output, index) => (
              <div
                key={`${id}-output-${output.id || index}`}
                className="handle-wrapper"
              >
                <span className="handle-label">
                  {output.name} ({output.dataType})
                </span>
                <Handle
                  type="source"
                  position={Position.Right}
                  id={`${id}-output-${output.id || index}`}
                />
              </div>
            ))}
          </div>
        </div>
        {!minimized && (
          <form className="node-form">
            {(data.inputFields || []).map((input) => (
              <div key={input.id} className="form-field">
                <label htmlFor={`${id}-${input.name}`}>{input.name}:</label>
                {input.dataType === "select" && (
                  <select
                    id={`${id}-${input.name}`}
                    name={input.name}
                    value={formData[input.name]}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    {input.options.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                )}
                {input.dataType === "boolean" && (
                  <input
                    type="checkbox"
                    id={`${id}-${input.name}`}
                    name={input.name}
                    checked={formData[input.name]}
                    onChange={handleInputChange}
                  />
                )}
                {input.dataType === "file" && (
                  <input
                    type="file"
                    id={`${id}-${input.name}`}
                    name={input.name}
                    onChange={handleFileChange}
                  />
                )}
                {input.dataType === "number" && (
                  <input
                    type="number"
                    id={`${id}-${input.name}`}
                    name={input.name}
                    value={formData[input.name]}
                    onChange={handleInputChange}
                    placeholder={input.name}
                  />
                )}
                {!["select", "boolean", "file", "number"].includes(
                  input.dataType
                ) && (
                    <input
                      type="text"
                      id={`${id}-${input.name}`}
                      name={input.name}
                      value={formData[input.name]}
                      onChange={handleInputChange}
                      placeholder={input.name}
                    />
                  )}
              </div>
            ))}
          </form>
        )}
      </div>
    </div>
  );
}

export default React.memo(PluginNode);
