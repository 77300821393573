import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { FaPowerOff } from "react-icons/fa";
export const Header = ({
  extraButtons = <></>,
  title = <span>Dashboard</span>,
}) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  // get current route
  const currentRoute = window.location.pathname;

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Failed to logout:", error);
    }
  };

  return (
    <header className="header">
      <div className="logo">
        <img
          src="/logo.svg"
          alt="Logo"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
        {title}
      </div>
      <nav>
        <ul>
          <li
            className={currentRoute.startsWith("/dashboard") ? "active" : ""}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Dashboard
          </li>
          <li
            className={currentRoute.startsWith("/newexplorer") ? "active" : ""}
            onClick={() => {
              navigate("/newexplorer");
            }}
          >
            Explorer
          </li>
        </ul>
      </nav>

      <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
        {extraButtons}
        <div className="icon-header">
          <div className="bell-icon">
            <img
              src="/bell-icon.svg"
              className="header-icon-size"
              alt="Bell-icon"
            />
          </div>
          <div className="setting-icon">
            <img
              src="/settings.svg"
              className="header-icon-size"
              alt="Bell-icon"
            />
          </div>
          <div className="user-info">
            <img
              src={user?.avatar || "/defaultuser.png"}
              alt="User avatar"
              className="avatar"
            />
            <div className="user-name">
              <div className="user-name-sub1">
                <span>
                  {user?.name?.trim().split(" ")[0] ||
                    user?.username?.trim().split(" ")[0] ||
                    "User"}
                </span>
                <h1>{user?.role}</h1>
              </div>
              <div className="dropdown-div">
                <img
                  src="/dropdown-icon.svg"
                  alt="DropDown"
                  
                />
              </div>
            </div>
          </div>
        </div>
        {/* <img
          src={user.avatar || "/default-avatar.png"}
          alt="User avatar"
          className="avatar"
        />
        <span style={{ paddingRight: 20 }}>
          {user.name || user.preferred_username || user.username || user.email || "Guest"}
        </span>
        <button onClick={handleLogout} className="logout-button">
          <FaPowerOff />
        </button> */}
      </div>
    </header>
  );
};
