import React, { useState, useCallback, useEffect } from "react";
import { Handle, Position } from "@xyflow/react";
import {
  FaCode,
  FaWindowMaximize,
  FaWindowMinimize,
  FaPlus,
  FaTrash,
} from "react-icons/fa";
import { QueryBuilder } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";

function ConditionNode({ data, id, onUpdate, selected }) {
  const [formData, setFormData] = useState(data);
  const [minimized, setMinimized] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onUpdate(id, formData).catch((err) => {
        setError(`Failed to update condition: ${err.message}`);
      });
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [formData, id, onUpdate]);

  const handleToggleMinimize = useCallback(() => {
    setMinimized((prev) => !prev);
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleAddInputField = useCallback(() => {
    setFormData((prev) => ({
      ...prev,
      inputFields: [
        ...prev.inputFields,
        { id: Date.now(), name: "", type: "string" },
      ],
    }));
  }, []);

  const handleDeleteInputField = useCallback((fieldId) => {
    setFormData((prev) => ({
      ...prev,
      inputFields: prev.inputFields.filter((field) => field.id !== fieldId),
    }));
  }, []);

  const handleInputFieldChange = useCallback((fieldId, key, value) => {
    setFormData((prev) => ({
      ...prev,
      inputFields: prev.inputFields.map((field) =>
        field.id === fieldId ? { ...field, [key]: value } : field
      ),
    }));
  }, []);

  const handleAddCondition = useCallback(() => {
    setFormData((prev) => {
      const ifCondition = prev.conditions[0];
      const elseCondition = prev.conditions.pop();
      return {
        ...prev,
        conditions: [
          ifCondition,
          ...prev.conditions.slice(1),
          {
            id: `elseif_${Date.now()}`,
            type: "elseif",
            name: "Else If",
            query: { combinator: "and", rules: [] },
          },
          elseCondition,
        ],
      };
    });
  }, [formData]);

  const handleDeleteCondition = useCallback((conditionId) => {
    setFormData((prev) => ({
      ...prev,
      conditions: prev.conditions.filter((c) => c.id !== conditionId),
    }));
  }, []);

  const handleQueryChange = useCallback((conditionId, query) => {
    setFormData((prev) => ({
      ...prev,
      conditions: prev.conditions.map((c) =>
        c.id === conditionId ? { ...c, query } : c
      ),
    }));
  }, []);

  return (
    <div
      className="condition-node"
      style={{
        border: selected ? "2px solid #3333ff50" : "1px solid #ddd",
        borderRadius: "5px",
        padding: "5px",
        background: "#3333ff50",
        maxWidth: "1200px",
        minWidth: "300px",
      }}
    >
      <div
        className="node-header"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: "10px",
          backgroundColor: "#3333ff50",
          padding: 10,
        }}
      >
        {minimized ? (
          <FaWindowMaximize
            onClick={handleToggleMinimize}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <FaWindowMinimize
            onClick={handleToggleMinimize}
            style={{ cursor: "pointer" }}
          />
        )}{" "}
        <FaCode /> <strong>{formData.name || "Condition"}</strong>
      </div>
      {error && (
        <div
          style={{
            backgroundColor: "#ffcccc",
            color: "red",
            padding: "5px",
            fontSize: "12px",
            marginBottom: "10px",
          }}
        >
          {error}
        </div>
      )}

      <>
        <div className="handles-container">
          <div className="input-handles">
            {formData.inputFields.map((field) => (
              <div key={`${id}-input-${field.id}`} className="handle-wrapper">
                <Handle
                  type="target"
                  position={Position.Left}
                  id={`${id}-input-${field.id}`}
                  style={{ left: 6 }}
                />
                <span className="handle-label">
                  {field.name} ({field.type})
                </span>
              </div>
            ))}
          </div>
          <div className="output-handles">
            {formData.conditions.map((condition, index) => (
              <div
                key={`${id}-condition-${condition.id}`}
                style={{ display: "flex", flexDirection: "column" }}
                className={`color-${index}`}
              >
                <span
                  className="handle-label"
                  style={{ fontWeight: "bolder", alignSelf: "flex-end" }}
                >
                  {condition.name}
                </span>
                {formData.inputFields.map((field) => (
                  <div
                    key={`${id}-output-${field.id}`}
                    className="handle-wrapper"
                  >
                    <span className="handle-label">
                      {field.name} ({field.type})
                    </span>
                    <Handle
                      type="source"
                      position={Position.Right}
                      id={`${id}-output-${condition.id}-${field.id}`}
                      style={{ right: 6 }}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        {!minimized && (
          <>
            <div style={{ marginBottom: "10px" }}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Condition Name"
                style={{ width: "95%" }}
              />
            </div>
            <div className="input-fields" style={{ marginBottom: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <h3>Input Fields:</h3>{" "}
                <FaPlus
                  onClick={handleAddInputField}
                  style={{ marginTop: "5px", cursor: "pointer" }}
                />
              </div>
              {formData.inputFields?.map((field, index) => (
                <div
                  key={`${id}-input-${field.id || index}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <input
                    type="text"
                    value={field.name}
                    onChange={(e) =>
                      handleInputFieldChange(field.id, "name", e.target.value)
                    }
                    placeholder="Field Name"
                    style={{ marginRight: "5px", width: "100px" }}
                  />
                  <select
                    value={field.type}
                    onChange={(e) =>
                      handleInputFieldChange(field.id, "type", e.target.value)
                    }
                    style={{ marginRight: "5px" }}
                  >
                    <option value="string">String</option>
                    <option value="number">Number</option>
                    <option value="boolean">Boolean</option>
                    <option value="date">Date</option>
                    <option value="file">File</option>
                    <option value="object">Object</option>
                  </select>
                  <FaTrash
                    onClick={() => handleDeleteInputField(field.id)}
                    style={{ cursor: "pointer", color: "red" }}
                  />
                </div>
              ))}
            </div>
            <div className="conditions" style={{ marginBottom: "10px" }}>
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <h3>Conditions:</h3>
                <FaPlus
                  onClick={handleAddCondition}
                  style={{ cursor: "pointer" }}
                />
              </div>
              {formData.conditions?.map((condition, index) => (
                <div
                  key={`${id}-condition-${condition.id || index}`}
                  style={{ marginBottom: "10px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <strong>
                      {condition.id === "if"
                        ? "If"
                        : condition.id === "else"
                        ? "Else"
                        : "Else If"}
                    </strong>
                    {condition.id !== "if" && condition.id !== "else" && (
                      <FaTrash
                        onClick={() => handleDeleteCondition(condition.id)}
                        style={{ cursor: "pointer", color: "red" }}
                      />
                    )}
                  </div>
                  {condition.id !== "else" && (
                    <QueryBuilder
                      controlClassnames={{
                        ruleGroup: `color-${index}`,
                      }}
                      fields={formData.inputFields.map((field) => ({
                        name: field.name,
                        label: field.name,
                        inputType: field.type,
                      }))}
                      query={condition.query}
                      onQueryChange={(q) => handleQueryChange(condition.id, q)}
                    />
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </>
    </div>
  );
}

export default React.memo(ConditionNode);
