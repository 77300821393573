import React, { useState, useCallback, useMemo, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Sidebar from "./Sidebar";
import EventNode from "./EventNode";
import EventHandlerNode from "./EventHandlerNode";
import PluginNode from "./PluginNode";
import ConditionNode from "./ConditionNode";
import TriggerNode from "./TriggerNode";
import EntitySection from "./EntitySection";
import PopupForm from "./PopupForm";
import {
  addEdge,
  Background,
  Controls,
  MarkerType,
  MiniMap,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import {
  entityApi,
  pluginApi,
  customApi,
  triggerApi,
  userApi,
  roleApi,
} from "../services/api";
import { useSearchParams } from "react-router-dom";
import { FaPlay } from "react-icons/fa";
import { useAuth } from "../context/AuthContext";
import { Header } from "./Header";
import ChatComponent from "./Copilot";

function Editor() {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedElements, setSelectedElements] = useState(null);
  const [isValidConnection, setIsValidConnection] = useState(true);
  const [entities, setEntities] = useState([]);
  const [plugins, setPlugins] = useState([]);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState("draft");
  const [showPopupForm, setShowPopupForm] = useState(false);
  const [selectedEventNode, setSelectedEventNode] = useState(null);
  const [triggers, setTriggers] = useState([]);

  useEffect(() => {
    let timeout = setInterval(() => {
      setMessage(null);
      setError(null);
    }, 5000);
    return () => clearInterval(timeout);
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const handleClosePopup = useCallback(() => {
    setShowPopupForm(false);
    setSelectedEventNode(null);
  }, []);
  const handlePluginRegistration = useCallback(async (pluginData) => {
    try {
      const response = await customApi.registerPlugin(pluginData);
      const newPlugin = response.data;
      setPlugins((prevPlugins) => [...prevPlugins, newPlugin]);
      setMessage("Plugin registered successfully");
    } catch (error) {
      console.error("Error registering plugin:", error);
      setError("Failed to register plugin. Please try again.");
    }
  }, []);

  const handleTriggerRegistration = useCallback(async (triggerData) => {
    try {
      const response = await triggerApi.create(triggerData);
      const newTrigger = response.data;
      setTriggers((prevTriggers) => [...prevTriggers, newTrigger]);
      setMessage("Trigger registered successfully");
    } catch (error) {
      console.error("Error registering trigger:", error);
      setError("Failed to register trigger. Please try again.");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          entitiesResponse,
          pluginsResponse,
          flowDataResponse,
          triggerResponse,
          userResponse,
          roleResponse,
        ] = await Promise.all([
          entityApi.getAll(),
          pluginApi.getAll(),
          customApi.getFlowData(searchParams.get("id")),
          triggerApi.getAll(),
          userApi.getAll(),
          roleApi.getAll(),
        ]);
        setEntities(entitiesResponse.data);
        setPlugins(pluginsResponse.data);
        setTriggers(triggerResponse.data);
        setRoles(roleResponse.data);
        setUsers(userResponse.data);
        // Set nodes and edges from flowData
        if (flowDataResponse.data) {
          console.log(flowDataResponse.data);
          setNodes(flowDataResponse.data.flow_data.nodes || []);
          setEdges(
            (flowDataResponse.data.flow_data.edges || []).map((edge) => ({
              ...edge,
              markerEnd: {
                type: MarkerType.Arrow,
                color: "#00300090",
              },
              zIndex: 1000,
              style: { stroke: "#00300090", strokeWidth: 3 },
            }))
          );
          setStatus(flowDataResponse.data.status);
        }
        setMessage("Data loaded successfully");
        console.log("Data loaded successfully", triggerResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try refreshing the page.");
      }
    };

    fetchData();
  }, [setNodes, setEdges, searchParams]);

  const nodeTypes = useMemo(
    () => ({
      eventNode: (props) => (
        <EventNode
          {...props}
          onUpdate={handleUpdateNode}
          entities={entities}
          users={users}
          roles={roles}
        />
      ),
      eventHandlerNode: (props) => (
        <EventHandlerNode
          {...props}
          entities={entities}
          onUpdate={handleUpdateNode}
          users={users}
          roles={roles}
        />
      ),
      pluginNode: (props) => (
        <PluginNode
          {...props}
          onUpdate={handleUpdateNode}
          entities={entities}
          users={users}
          roles={roles}
        />
      ),
      conditionNode: (props) => (
        <ConditionNode {...props} onUpdate={handleUpdateNode} />
      ),
      triggerNode: (props) => (
        <TriggerNode
          {...props}
          onUpdate={handleUpdateNode}
          entities={entities}
          users={users}
          roles={roles}
        />
      ),
    }),
    [entities]
  );

  useEffect(() => {
    // Update existing EventHandlerNodes with the new entities data
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.type === "eventHandlerNode"
          ? { ...node, data: { ...node.data, entities } }
          : node
      )
    );
  }, [entities, setNodes]);

  const isValidConnectionHelper = useCallback((source, target) => {
    const sourceType = source.split("__")[0];
    const targetType = target.split("__")[0];

    if (
      sourceType === "eventHandlerNode" ||
      sourceType === "pluginNode" ||
      sourceType === "conditionNode" ||
      sourceType === "triggerNode"
    ) {
      return true;
    }

    if (sourceType === "eventNode") {
      return ["eventHandlerNode", "pluginNode", "conditionNode"].includes(
        targetType
      );
    }

    if (sourceType === "conditionNode") {
      return ["eventHandlerNode", "pluginNode", "conditionNode"].includes(
        targetType
      );
    }

    if (["eventHandlerNode", "pluginNode"].includes(sourceType)) {
      return ["eventHandlerNode", "pluginNode", "conditionNode"].includes(
        targetType
      );
    }

    return false;
  }, []);

  const onConnect = useCallback(
    (params) => {
      if (isValidConnectionHelper(params.source, params.target)) {
        setEdges((eds) =>
          addEdge(
            {
              ...params,
              markerEnd: {
                type: MarkerType.ArrowClosed,
                color: "black",
              },
              style: { stroke: "black", strokeWidth: 6 },
            },
            eds
          )
        );
      } else {
        setError("Invalid connection. Please check your node connections.");
      }
    },
    [isValidConnectionHelper, setEdges]
  );

  const onConnectStart = useCallback(() => {
    setIsValidConnection(true);
    setError(null);
  }, []);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = document
        .querySelector(".react-flow__renderer")
        .getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      const pluginName = event.dataTransfer.getData("application/pluginName");
      const triggerName = event.dataTransfer.getData("application/triggerName");
      const position = {
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      };

      let newNode;

      if (type === "pluginNode") {
        const plugin = plugins.find((p) => p.name === pluginName);
        if (plugin) {
          newNode = {
            id: `${type}__${uuidv4()}`,
            type,
            position,
            data: {
              ...plugin,
              name: plugin.name,
              inputFields: plugin.input_mapping.map((field) => ({
                ...field,
                id: uuidv4(),
              })),
              outputFields: plugin.output_mapping.map((field) => ({
                ...field,
                id: uuidv4(),
              })),
              model: plugin.model,
              plugin_registry_id: plugin.id,
            },
          };
        }
      } else if (type === "eventNode") {
        newNode = {
          id: `${type}__${uuidv4()}`,
          type,
          position,
          data: { name: "New Event" },
        };
      } else if (type === "eventHandlerNode") {
        newNode = {
          id: `${type}__${uuidv4()}`,
          type,
          position,
          data: { name: "New Event Handler", entities },
        };
      } else if (type === "conditionNode") {
        newNode = {
          id: `${type}__${uuidv4()}`,
          type,
          position,
          data: {
            name: "New Condition",
            conditions: [
              {
                type: "if",
                name: "if",
                id: "if",
                query: { combinator: "and", rules: [] },
              },
              {
                type: "else",
                name: "else",
                id: "else",
                query: { combinator: "and", rules: [] },
              },
            ],
            inputFields: [],
          },
        };
      } else if (type === "triggerNode") {
        const trigger = triggers.find((t) => t.name === triggerName);
        newNode = {
          id: `${type}__${uuidv4()}`,
          type,
          position,
          data: {
            ...trigger,
            name: trigger.name,
            inputFields: trigger.inputFields.map((field) => ({
              ...field,
              id: uuidv4(),
            })),
            outputFields: trigger.outputFields.map((field) => ({
              ...field,
              id: uuidv4(),
            })),
            trigger_registry_id: trigger.id,
          },
        };
        if (trigger.registrationCode) {
          const triggerRegistry = trigger;
          eval(
            triggerRegistry.registrationCode ||
              `var newwindow=window.open(triggerRegistry.registerAPI,'register', 'height=200,width=150');
if (window.focus) {newwindow.focus()}`
          );
        }
      }

      if (newNode) {
        setNodes((nds) => nds.concat(newNode));
      }
    },
    [setNodes, entities, plugins]
  );

  const onSelectionChange = useCallback((elements) => {
    setSelectedElements(elements);
    if (elements?.nodes?.length === 1) {
      setSelectedNode(elements.nodes[0]);
    } else {
      setSelectedNode(null);
    }
  }, []);

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === "Delete") {
        if (selectedElements) {
          const selectedNodes = selectedElements.nodes;
          const selectedEdges = selectedElements.edges;

          if (selectedNodes?.length) {
            const remainingNodes = nodes.filter(
              (node) =>
                !selectedNodes.find(
                  (selectedNode) => selectedNode.id === node.id
                )
            );
            setNodes(remainingNodes);
          }

          if (selectedEdges?.length) {
            const remainingEdges = edges.filter(
              (edge) =>
                !selectedEdges.find(
                  (selectedEdge) => selectedEdge.id === edge.id
                )
            );
            setEdges(remainingEdges);
          }
        }
      }
    },
    [selectedElements, nodes, edges, setNodes, setEdges]
  );
  const handleRun = async () => {
    console.log("Running flow");
    const flowData = { nodes, edges };
    try {
      // get first event node such that is has no incoming edges
      const eventNode = flowData.nodes.find((node) => {
        return !flowData.edges.some((edge) => edge.target === node.id);
      });
      console.log("Event Node", eventNode);
      if (eventNode) {
        setSelectedEventNode(eventNode);
        setShowPopupForm(true);
      } else {
        setError("No valid event node found to start the flow.");
      }
    } catch (error) {
      console.error("Error preparing to run flow:", error);
      setError("Failed to prepare flow execution. Please try again.");
    }
  };

  const handleFormSubmit = async (formData) => {
    try {
      console.log("Form Data:", formData);
      formData.set("flowId", searchParams.get("id"));
      formData.set("clientId", user.clientId);
      const response = await customApi.triggerEvent(
        selectedEventNode.id,
        formData
      );
      console.log("Event triggered:", response);
      setMessage("Flow triggered successfully");
    } catch (error) {
      console.error("Error running flow:", error);
      setError("Failed to run flow. Please try again.");
    } finally {
      setShowPopupForm(false);
      setSelectedEventNode(null);
    }
  };
  const saveData = async () => {
    console.log("Saving data:", { nodes, edges, entities });
    try {
      // Save entities
      await Promise.all(
        entities.map((entity) => entityApi.update(entity.id, entity))
      );

      // Save flow data (nodes and edges)
      await customApi
        .saveFlowData({
          flow_data: { nodes, edges },
          status,
          id: searchParams.get("id"),
        })
        .then((response) => {
          setStatus(response.data.status);
          response.data.id && setSearchParams({ id: response.data.id });
        });

      console.log("Save completed");
      setMessage("Data saved successfully");
    } catch (error) {
      console.error("Error saving data:", error);
      setError("Failed to save data. Please try again.");
    }
  };

  useEffect(() => {
    // const autosaveInterval = setInterval(() => {
    //   saveData();
    // }, 60000); // Autosave every 60 seconds
    // return () => clearInterval(autosaveInterval);
  }, [nodes, edges, entities]);

  const handleCreateEntity = useCallback(async (name) => {
    try {
      const response = await entityApi.create({ name, fields: [] });
      const newEntity = response.data;
      setEntities((prevEntities) => [...prevEntities, newEntity]);
      setMessage("Entity created successfully");
    } catch (error) {
      console.error("Error creating entity:", error);
      setError("Failed to create entity. Please try again.");
    }
  }, []);

  const handleUpdateNode = useCallback(
    async (nodeId, newData) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) {
            return { ...node, data: { ...node.data, ...newData } };
          }
          return node;
        })
      );
      // // Update the node in the backend
      // try {
      //   if (nodeId.startsWith("eventNode")) {
      //     await eventApi.update(nodeId, newData);
      //   } else if (nodeId.startsWith("eventHandlerNode")) {
      //     await eventHandlerApi.update(nodeId, newData);
      //   } else if (nodeId.startsWith("pluginNode")) {
      //     await pluginApi.update(nodeId, newData);
      //   }
      // } catch (error) {
      //   console.error("Error updating node:", error);
      //   setError("Failed to update node. Please try again.");
      // }
    },
    [setNodes]
  );

  const handleAddField = useCallback(
    async (entityId, data) => {
      try {
        const entity = entities.find((e) => e.id === entityId);
        if (!entity) throw new Error("Entity not found");

        const updatedFields = [...entity.fields, { id: uuidv4(), ...data }];
        const updatedEntity = { ...entity, fields: updatedFields };

        await entityApi.update(entityId, updatedEntity);

        setEntities((prevEntities) =>
          prevEntities.map((e) => (e.id === entityId ? updatedEntity : e))
        );
        setMessage("Field added successfully");
      } catch (error) {
        console.error("Error adding field:", error);
        setError("Failed to add field. Please try again.");
      }
    },
    [entities]
  );

  const handleDeleteEntity = useCallback(
    async (entityId) => {
      try {
        await entityApi.delete(entityId);
        setEntities((prevEntities) =>
          prevEntities.filter((entity) => entity.id !== entityId)
        );
        // Update nodes to remove references to the deleted entity
        setNodes((prevNodes) =>
          prevNodes.map((node) => {
            if (node.type === "eventHandlerNode") {
              const updatedEntityReferences =
                node.data.entityReferences?.filter(
                  (ref) => ref.entity !== entityId
                ) || [];
              return {
                ...node,
                data: {
                  ...node.data,
                  entityReferences: updatedEntityReferences,
                },
              };
            }
            return node;
          })
        );
      } catch (error) {
        console.error("Error deleting entity:", error);
        setError("Failed to delete entity. Please try again.");
      }
    },
    [setNodes]
  );

  const handleDeleteField = useCallback(
    async (entityId, fieldId) => {
      try {
        const entity = entities.find((e) => e.id === entityId);
        if (!entity) throw new Error("Entity not found");

        const updatedFields = entity.fields.filter(
          (field) => field.id !== fieldId
        );
        const updatedEntity = { ...entity, fields: updatedFields };

        await entityApi.update(entityId, updatedEntity);

        setEntities((prevEntities) =>
          prevEntities.map((e) => (e.id === entityId ? updatedEntity : e))
        );

        // Update nodes to remove references to the deleted field
        setNodes((prevNodes) =>
          prevNodes.map((node) => {
            if (node.type === "eventHandlerNode") {
              const updatedEntityReferences = node.data.entityReferences?.map(
                (ref) => {
                  if (ref.entity === entityId) {
                    return {
                      ...ref,
                      query: {
                        ...ref.query,
                        rules: ref.query.rules.filter(
                          (rule) => rule.field !== fieldId
                        ),
                      },
                    };
                  }
                  return ref;
                }
              );
              return {
                ...node,
                data: {
                  ...node.data,
                  entityReferences: updatedEntityReferences,
                },
              };
            }
            return node;
          })
        );
      } catch (error) {
        console.error("Error deleting field:", error);
        setError("Failed to delete field. Please try again.");
      }
    },
    [entities, setNodes]
  );
  const { user, logout } = useAuth();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Header
        title={<span>Flow Editor</span>}
        extraButtons={
          <>
            <button
              style={{
                padding: "10px",
                backgroundColor: "#ff6666",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={handleRun}
            >
              <FaPlay />
            </button>
            <button
              onClick={saveData}
              style={{
                padding: "10px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              Save
            </button>
          </>
        }
      />
      {error && (
        <div
          style={{
            backgroundColor: "#ffcccc",
            color: "red",
            padding: "10px",
            textAlign: "center",
          }}
        >
          {error}
        </div>
      )}
      {message && (
        <div
          style={{
            backgroundColor: "#ccffcc",
            color: "red",
            padding: "10px",
            textAlign: "center",
          }}
        >
          {message}
        </div>
      )}
      <PanelGroup direction="horizontal" style={{ flex: 1 }}>
        <Panel defaultSize={20} minSize={10}>
          <Sidebar
            selectedElement={selectedNode}
            plugins={plugins}
            onUpdateNode={handleUpdateNode}
            entities={entities}
            onPluginRegistered={handlePluginRegistration}
            triggers={triggers}
            onTriggerRegistered={handleTriggerRegistration}
          />
        </Panel>
        <PanelResizeHandle style={{ width: "5px", background: "#ccc" }} />
        <Panel>
          <PanelGroup direction="vertical">
            <Panel>
              <ReactFlow
                onKeyDown={onKeyDown}
                nodes={nodes}
                maxZoom={30}
                minZoom={0.1}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onConnectStart={onConnectStart}
                onSelectionChange={onSelectionChange}
                nodeTypes={nodeTypes}
                onDragOver={onDragOver}
                onDrop={onDrop}
                deleteKeyCode={""} // Disable default delete behavior
                connectionLineStyle={
                  isValidConnection
                    ? { stroke: "black", strokeWidth: 6 }
                    : { stroke: "red" }
                }
                proOptions={{
                  hideAttribution: false,
                }}
                style={{ height: "100%" }}
              >
                <MiniMap />
                <Controls />
                <Background />
              </ReactFlow>
            </Panel>
            <PanelResizeHandle style={{ height: "5px", background: "#ccc" }} />
            <Panel defaultSize={30} minSize={20}>
              <EntitySection
                entities={entities}
                onCreateEntity={handleCreateEntity}
                onAddField={handleAddField}
                onDeleteEntity={handleDeleteEntity}
                onDeleteField={handleDeleteField}
              />
            </Panel>
          </PanelGroup>
        </Panel>
      </PanelGroup>
      {showPopupForm && selectedEventNode && (
        <PopupForm
          fields={
            selectedEventNode.data.fields || selectedEventNode.data.inputFields
          }
          onSubmit={handleFormSubmit}
          onClose={handleClosePopup}
          onBackdropClick={handleClosePopup}
        />
      )}
      <ChatComponent
        onSendMessage={() => {
          return {};
        }}
      />
    </div>
  );
}

export default Editor;
