import React, { useState } from "react";
import FormData from 'form-data';

const PopupForm = ({ fields, onSubmit, onClose, onBackdropClick }) => {
  const [formData, setFormData] = useState({});

  const handleInputChange = (e, field) => {
    let value = field.type === "boolean" ? e.target.checked : e.target.value;
    // if file input, set the file object
    if (field.type === "file") {
        value = e.target.files[0];
    }
    setFormData({ ...formData, [field.name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // convert formData to new FormData object
    const formDataObj = new FormData();
    for (const key in formData) {
      formDataObj.append(key, formData[key]);
    }
    
    onSubmit(formDataObj);
  };

  const renderInput = (field) => {
    switch (field.type) {
      case "string":
        return (
          <input
            type="text"
            name={field.name}
            value={formData[field.name] || ""}
            onChange={(e) => handleInputChange(e, field)}
          />
        );
      case "number":
        return (
          <input
            type="number"
            name={field.name}
            value={formData[field.name] || ""}
            onChange={(e) => handleInputChange(e, field)}
          />
        );
      case "date":
        return (
          <input
            type="datetime-local"
            name={field.name}
            value={formData[field.name] || ""}
            onChange={(e) => handleInputChange(e, field)}
          />
        );
      case "boolean":
        return (
          <input
            type="checkbox"
            name={field.name}
            checked={formData[field.name] || false}
            onChange={(e) => handleInputChange(e, field)}
          />
        );
      case "file":
        return (
          <input
            type="file"
            name={field.name}
            onChange={(e) => handleInputChange(e, field)}
          />
        );
      default:
        return (
          <input
            type="text"
            name={field.name}
            value={formData[field.name] || ""}
            onChange={(e) => handleInputChange(e, field)}
          />
        );
    }
  };

  return (
    <div
      onClick={onBackdropClick}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          maxWidth: "500px",
          width: "100%",
        }}
      >
        <h2 style={{ marginBottom: "20px" }}>Enter Event Data</h2>
        <form onSubmit={handleSubmit}>
          {fields.map((field) => (
            <div key={field.name} style={{ marginBottom: "15px" }}>
              <label style={{ display: "block", marginBottom: "5px" }}>
                {field.name}
              </label>
              {renderInput(field)}
            </div>
          ))}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <button
              type="button"
              onClick={onClose}
              style={{
                marginRight: "10px",
                padding: "8px 15px",
                backgroundColor: "#f0f0f0",
                border: "none",
                borderRadius: "3px",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              style={{
                padding: "8px 15px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "3px",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;
