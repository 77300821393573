import React, { createContext, useState, useContext, useEffect } from 'react';
import { initiateLogin, handleCallback, logout as apiLogout, getCurrentUser, refreshToken } from '../services/api';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoggedIn = async () => {
      const accessToken = localStorage.getItem('access_token');
      if (accessToken) {
        try {
          const userData = await getCurrentUser();
          setUser(userData);
        } catch (error) {
          console.error('Failed to get current user:', error);
          await refreshTokens();
        }
      }
      setLoading(false);
    };

    checkLoggedIn();
  }, []);

  const refreshTokens = async () => {
    try {
      await refreshToken();
      const userData = await getCurrentUser();
      setUser(userData);
    } catch (error) {
      console.error('Failed to refresh tokens:', error);
      await logout();
    }
  };

  const login = async () => {
    try {
      const loginUrl = await initiateLogin();
      return loginUrl;
    } catch (error) {
      console.error('Failed to initiate login:', error);
      setError('Failed to initiate login. Please try again.');
      throw error;
    }
  };

  const handleLoginCallback = async (params) => {
    try {
      const session = localStorage.getItem('state');
      const tokens = await handleCallback(params, session);
      localStorage.setItem('access_token', tokens.access_token);
      localStorage.setItem('id_token', tokens.id_token);
      localStorage.setItem('refresh_token', tokens.refresh_token);
      const userData = await getCurrentUser();
      setUser(userData);
      setError(null);
    } catch (error) {
      console.error('Failed to handle login callback:', error);
      setError('Failed to complete login. Please try again.');
      throw error;
    }
  };

  const logout = async () => {
    try {
      await apiLogout();
      setUser(null);
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('refresh_token');
      setError(null);
    } catch (error) {
      console.error('Failed to logout:', error);
      // setError('Failed to log out. Please try again.');
      setTimeout(() => navigate('/login'), 3000);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, handleLoginCallback, logout, loading, error }}>
      {children}
    </AuthContext.Provider>
  );
};