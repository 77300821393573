// src/components/PluginNodeForm.jsx

import React, { useState, useCallback, useEffect } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';

const PluginNodeForm = ({ node, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: node.data.name || '',
    description: node.data.description || '',
    icon: node.data.icon || '',
    inputs: node.data.inputs || [],
    outputs: node.data.outputs || [],
    model: node.data.model || {}
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    setFormData({
      name: node.data.name || '',
      description: node.data.description || '',
      icon: node.data.icon || '',
      inputs: node.data.inputs || [],
      outputs: node.data.outputs || [],
      model: node.data.model || {}
    });
  }, [node]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }, []);

  const handleFieldChange = useCallback((type, id, key, value) => {
    setFormData(prevData => ({
      ...prevData,
      [type]: prevData[type].map(field =>
        field.id === id ? { ...field, [key]: value } : field
      )
    }));
  }, []);

  const handleAddField = useCallback((type) => {
    setFormData(prevData => ({
      ...prevData,
      [type]: [...prevData[type], { id: Date.now(), name: '', type: 'string' }]
    }));
  }, []);

  const handleDeleteField = useCallback((type, id) => {
    setFormData(prevData => ({
      ...prevData,
      [type]: prevData[type].filter(field => field.id !== id)
    }));
  }, []);

  const handleModelChange = useCallback((key, value) => {
    setFormData(prevData => ({
      ...prevData,
      model: { ...prevData.model, [key]: value }
    }));
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setError(null);

    // Basic validation
    if (!formData.name.trim()) {
      setError('Plugin name is required');
      return;
    }

    if (formData.inputs.some(field => !field.name.trim()) || 
        formData.outputs.some(field => !field.name.trim())) {
      setError('All fields must have a name');
      return;
    }

    onSubmit(node.id, formData).catch(err => {
      setError(`Failed to update plugin: ${err.message}`);
    });
  }, [node.id, formData, onSubmit]);

  const renderField = useCallback((field, type) => (
    <div key={field.id} style={{ marginBottom: '10px' }}>
      <input
        type="text"
        value={field.name}
        onChange={(e) => handleFieldChange(type, field.id, 'name', e.target.value)}
        placeholder="Field Name"
        required
      />
      <select
        value={field.type}
        onChange={(e) => handleFieldChange(type, field.id, 'type', e.target.value)}
      >
        <option value="string">String</option>
        <option value="number">Number</option>
        <option value="boolean">Boolean</option>
        <option value="file">File</option>
        <option value="object">Object</option>
        <option value="data">Date</option>
      </select>
      {field.type === 'select' && (
        <input
          type="text"
          value={field.options ? field.options.join(', ') : ''}
          onChange={(e) => handleFieldChange(type, field.id, 'options', e.target.value.split(', '))}
          placeholder="Options (comma-separated)"
        />
      )}
      <FaTrash
        onClick={() => handleDeleteField(type, field.id)}
        style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
      />
    </div>
  ), [handleFieldChange, handleDeleteField]);

  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <div style={{ color: 'red', marginBottom: '10px' }}>
          {error}
        </div>
      )}
      <div>
        <label htmlFor="name">Plugin Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor="description">Description:</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label htmlFor="icon">Icon:</label>
        <input
          type="text"
          id="icon"
          name="icon"
          value={formData.icon}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <h4>Input Fields:</h4>
        {formData.inputs.map(field => renderField(field, 'inputs'))}
        <button type="button" onClick={() => handleAddField('inputs')}>
          <FaPlus /> Add Input Field
        </button>
      </div>
      <div>
        <h4>Output Fields:</h4>
        {formData.outputs.map(field => renderField(field, 'outputs'))}
        <button type="button" onClick={() => handleAddField('outputs')}>
          <FaPlus /> Add Output Field
        </button>
      </div>
      <div>
        <h4>Model:</h4>
        {Object.entries(formData.model).map(([key, value]) => (
          <div key={key}>
            <label htmlFor={`model-${key}`}>{key}:</label>
            <input
              type="text"
              id={`model-${key}`}
              value={value}
              onChange={(e) => handleModelChange(key, e.target.value)}
            />
          </div>
        ))}
        <button type="button" onClick={() => handleModelChange(`field-${Date.now()}`, '')}>
          <FaPlus /> Add Model Field
        </button>
      </div>
      <button type="submit">Update Plugin</button>
    </form>
  );
};

export default React.memo(PluginNodeForm);