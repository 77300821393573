import React from 'react';
import { useAuth } from '../context/AuthContext';

const Login = () => {
  const { login } = useAuth();

  const handleLogin = async () => {
    try {
      const loginUrl = await login();
      window.localStorage.setItem('state', loginUrl.session);
      window.location.href = loginUrl.url;

    } catch (error) {
      console.error('Failed to initiate login:', error);
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <button onClick={handleLogin}>Login with OIDC Provider</button>
    </div>
  );
};

export default Login;