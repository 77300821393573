import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { FaRocket, FaUser, FaWindowMinimize } from "react-icons/fa";

const ChatComponent = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");
  const chatMessagesRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const handleSendMessage = () => {
    if (message.trim()) {
      setLoading(true);
      setMessages((messages) => [
        ...messages,
        { role: "user", content: message },
      ]);
      onSendMessage(message)
        .then((responseMessage) => {
          if (responseMessage)
            setMessages((messages) => [...messages, responseMessage]);
        })
        .finally(() => {
          setMessage("");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [message]);

  return (
    <div
      className="chat-component"
      style={{
        position: "fixed",
        bottom: 20,
        right: 20,
        maxWidth: 300,
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        borderRadius: 5,
        boxShadow: "-10px 10px 10px rgba(0,0,0,0.3)",
      }}
    >
      <div style={{ backgroundColor: "#CCC", padding: 10 }}>
        {!minimized && <><FaWindowMinimize onClick={() => setMinimized(!minimized)} style={{width: 10, fontWeight: 200}}/><span style={{marginLeft: 10}}>Assistant</span> </>} 
        {minimized && <FaUser onClick={() => setMinimized(!minimized)} style={{width: 10, fontWeight: 200}}/>}
      </div>
      {!minimized && (
        <div style={{padding: 10,}}>
          <div
            ref={chatMessagesRef}
            className="chat-messages"
            style={{ maxHeight: 200, overflowY: "auto", marginBottom: 10 }}
          >
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`chat-message ${msg.role}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: msg.role == "user" ? "flex-start" : "flex-end",
                  padding: 5,
                  justifyContent:
                    msg.role == "user" ? "flex-start" : "flex-end",
                }}
              >
                <span>
                  <b>{msg.role}: </b>
                  {msg.content || msg.text}
                </span>
              </div>
            ))}
            {/* Render chat messages here */}
          </div>
          <div style={{flex:1, display:'flex', flexDirection: 'row'}}>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type a message..."
              style={{ width: "100%", marginBottom: 5 }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
            />
            <FaRocket onClick={handleSendMessage} style={{cursor: 'pointer', fontSize: 18 ,marginLeft: 5}}/>
          </div>
        </div>
      )}
    </div>
  );
};

ChatComponent.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
};

export default ChatComponent;
