// src/components/TriggerRegistrationForm.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaTrash, FaCode } from 'react-icons/fa';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";

function CodeEditorPopup({ code, onSave, onClose, title }) {
  const [editorCode, setEditorCode] = useState(code);

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h3>{title}</h3>
        <AceEditor
          mode="javascript"
          theme="monokai"
          onChange={setEditorCode}
          value={editorCode}
          name="code_editor"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            useWorker: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
          style={{ width: "100%", height: "300px" }}
        />
        <div className="popup-actions">
          <button onClick={() => onSave(editorCode)}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

CodeEditorPopup.propTypes = {
  code: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const TriggerRegistrationForm = ({ onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    inputFields: [{ id: Date.now(), name: '', dataType: 'string', required: false, validation: '' }],
    outputFields: [{ id: Date.now() + 1, name: '', dataType: 'string', required: false, validation: '' }],
    synchronous: false,
    cronExpression: '',
    checkAPI: '',
    registerAPI: '',
    registrationCode: '',
    checkAPICode: '',
  });
  const [error, setError] = useState('');
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [currentEditingField, setCurrentEditingField] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleFieldChange = (type, id, field, value) => {
    setFormData(prevState => ({
      ...prevState,
      [type]: prevState[type].map(item => 
        item.id === id ? { ...item, [field]: value } : item
      )
    }));
  };

  const addField = (type) => {
    setFormData(prevState => ({
      ...prevState,
      [type]: [...prevState[type], { id: Date.now(), name: '', dataType: 'string', required: false, validation: '' }]
    }));
  };

  const removeField = (type, id) => {
    setFormData(prevState => ({
      ...prevState,
      [type]: prevState[type].filter(item => item.id !== id)
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    // Client-side validation
    if (formData.name.trim().length === 0) {
      setError('Name is required and must not be empty');
      return;
    }

    if (formData.inputFields.some(f => !f.name) || formData.outputFields.some(f => !f.name)) {
      setError('All field names are required');
      return;
    }

    if (formData.synchronous && !formData.cronExpression) {
      setError('Cron expression is required for synchronous triggers');
      return;
    }

    if (!formData.synchronous && !formData.registerAPI) {
      setError('Register API is required for asynchronous triggers');
      return;
    }

    // Prepare data for submission
    const submissionData = {
      ...formData,
      inputFields: formData.inputFields.map(({ id, ...rest }) => rest),
      outputFields: formData.outputFields.map(({ id, ...rest }) => rest),
    };

    onSubmit(submissionData);
  };

  const renderFields = (type) => {
    return formData[type].map((field) => (
      <div key={field.id} style={{ marginBottom: '10px', border: '1px solid #ccc', padding: '10px', display: 'flex', flexDirection: 'row' }}>
        <input
          type="text"
          placeholder="Name"
          value={field.name}
          onChange={(e) => handleFieldChange(type, field.id, 'name', e.target.value)}
          style={{ width: '100%', marginBottom: '5px', marginRight: '5px' }}
          required
        />
        <select
          value={field.dataType}
          onChange={(e) => handleFieldChange(type, field.id, 'dataType', e.target.value)}
          style={{ width: '100%', marginBottom: '5px', marginRight: '5px' }}
          required
        >
          <option value="string">String</option>
          <option value="number">Number</option>
          <option value="boolean">Boolean</option>
          <option value="file">File</option>
          <option value="object">Object</option>
          <option value="date">Date</option>
        </select>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', marginRight: '5px' }}>
          <input
            type="checkbox"
            id={`required-${field.id}`}
            checked={field.required}
            onChange={(e) => handleFieldChange(type, field.id, 'required', e.target.checked)}
            style={{ marginRight: '5px' }}
          />
          <label htmlFor={`required-${field.id}`}>Required</label>
        </div>
        <input
          type="text"
          placeholder="Validation (optional)"
          value={field.validation}
          onChange={(e) => handleFieldChange(type, field.id, 'validation', e.target.value)}
          style={{ width: '100%', marginBottom: '5px', marginRight: '5px' }}
        />
        <button type="button" onClick={() => removeField(type, field.id)}><FaTrash /></button>
      </div>
    ));
  };

  const handleOpenCodeEditor = (field) => {
    setCurrentEditingField(field);
    setShowCodeEditor(true);
  };

  const handleCodeEditorSave = (newCode) => {
    setFormData(prevState => ({
      ...prevState,
      [currentEditingField]: newCode
    }));
    setShowCodeEditor(false);
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        width: '600px',
        maxHeight: '80vh',
        overflowY: 'auto',
      }}>
        <h2>Register New Trigger</h2>
        {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              style={{ width: '100%', padding: '5px' }}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              style={{ width: '100%', padding: '5px', minHeight: '60px' }}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <h3>Input Fields</h3>
            {renderFields('inputFields')}
            <button type="button" onClick={() => addField('inputFields')}>Add Input Field</button>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <h3>Output Fields</h3>
            {renderFields('outputFields')}
            <button type="button" onClick={() => addField('outputFields')}>Add Output Field</button>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="synchronous">
              <input
                type="checkbox"
                id="synchronous"
                name="synchronous"
                checked={formData.synchronous}
                onChange={handleChange}
                style={{ marginRight: '5px' }}
              />
              Synchronous
            </label>
          </div>
            <>
              <div style={{ marginBottom: '10px' }}>
                <label htmlFor="cronExpression">Cron Expression:</label>
                <input
                  type="text"
                  id="cronExpression"
                  name="cronExpression"
                  value={formData.cronExpression}
                  onChange={handleChange}
                  style={{ width: '100%', padding: '5px' }}
                />
              </div>
              <div style={{ marginBottom: '10px' }}>
                <label htmlFor="checkAPI">Check API:</label>
                <input
                  type="text"
                  id="checkAPI"
                  name="checkAPI"
                  value={formData.checkAPI}
                  onChange={handleChange}
                  style={{ width: '100%', padding: '5px' }}
                />
              </div>
            </>
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="registerAPI">Register API:</label>
              <input
                type="text"
                id="registerAPI"
                name="registerAPI"
                value={formData.registerAPI}
                onChange={handleChange}
                style={{ width: '100%', padding: '5px' }}
              />
            </div>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="registrationCode">Registration Code:</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button type="button" onClick={() => handleOpenCodeEditor('registrationCode')} style={{ marginRight: '10px' }}>
                <FaCode /> Edit Code
              </button>
              <span>{formData.registrationCode ? 'Code added' : 'No code added'}</span>
            </div>
          </div>
          {formData.synchronous && (
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="checkAPICode">Check API Code:</label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button type="button" onClick={() => handleOpenCodeEditor('checkAPICode')} style={{ marginRight: '10px' }}>
                  <FaCode /> Edit Code
                </button>
                <span>{formData.checkAPICode ? 'Code added' : 'No code added'}</span>
              </div>
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button type="submit" style={{ padding: '5px 10px' }}>Register Trigger</button>
            <button type="button" onClick={onClose} style={{ padding: '5px 10px' }}>Cancel</button>
          </div>
        </form>
      </div>
      {showCodeEditor && (
        <CodeEditorPopup
          code={formData[currentEditingField]}
          onSave={handleCodeEditorSave}
          onClose={() => setShowCodeEditor(false)}
          title={currentEditingField === 'registrationCode' ? 'Registration Code' : 'Check API Code'}
        />
      )}
    </div>
  );
};

TriggerRegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TriggerRegistrationForm;
