import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";

import "./Dashboard.css";

export const MonitorInfrastructure = ({ entityData, columns }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [serverName, setServerName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const Modal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="create-server-header">
            <h2 className="title">Create Server</h2>
            <button className="create-server-close-button" onClick={onClose}>
              <img
                src="/close-icon.svg"
                alt="close-icon"
                style={{ width: "25px", height: "25px" }}
              />
            </button>
          </div>
          <div className="create-server-container">
            <div style={{ width: "95%" }}>
              <label>Server Name*</label>
              <input
                type="text"
                placeholder="Enter Server Name"
                value={serverName}
                onChange={(e) => setServerName(e.target.value)}
                className="serverName-container"
              />
              <div
                className="create-button-container"
              >
                <div className="create-button-row-container ">
                <button className="create-server-cancel" onClick={onClose}><span style={{ color: '#E65F2B' }}>Cancel</span></button>
                <button className="create-server-add"><span style={{ color: '#ffff'}}>Add</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const customStyles = useMemo(() => {
    const baseStyles = {
      headRow: {
        style: {
          backgroundColor: "#F9FAFB",
          color: "#6B7280",
          marginBottom: "10px",
        },
      },
      headCells: {
        style: {
          borderRight: "1px solid #D1D1D1",
          padding: "12px 8px",
        },
      },
      rows: {
        style: {
          minHeight: "48px",
        },
      },
    };

    // Conditionally add cells style only if entityData is not empty
    if (entityData.length > 0) {
      baseStyles.cells = {
        style: {
          borderRight: "1px solid #D1D1D1",
          padding: "12px 8px",
          borderBottom: "1px solid #D1D1D1"
        },
      };
    }

    return baseStyles;
  }, [entityData]);

  return (
    <>
      <div className="dashboard-monitor-Infrastructure">
        <div className="monitor-Infrastructure-header">
          <div className="monitor-Infrastructure">
            <h1>MONITOR INFRASTRUCTURE (50)</h1>
          </div>
          <div className="create-flow-infra">
            <div className="relative-container-infra">
              <div className="search-icon">
                <img src="/search-icon.svg" alt="Search Icon" />
              </div>
              <input
                type="text"
                placeholder="Search for anything..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
            </div>
            <button
              className="create-flow-button"
              // onClick={() => navigate("/editor")}
              onClick={() => setIsModalOpen(true)}
            >
              <div
                style={{ gap: "6px", flexDirection: "row", display: "flex" }}
              >
                <div
                  style={{ justifyContent: "center", alignContent: "center" }}
                >
                  <img
                    src="/add-icon.svg"
                    alt="Add Icon"
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      width: "18px",
                      height: "18px",
                    }}
                  />
                </div>
                <div className="create-flow">
                  <span>Create Server</span>
                </div>
              </div>
            </button>
          </div>
        </div>

        <div className="infra-table">
          {entityData.length > 0 ? (
            <DataTable
              columns={columns}
              data={entityData.length > 0 ? entityData : []}
              customStyles={customStyles}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5]}
            />
          ) : (
            <div className="infra-default">
              <DataTable
                columns={columns}
                data={entityData.length > 0 ? entityData : [{}]}
                customStyles={customStyles}
              />
              <img src="/image1.png" alt="" className="infra-image" />
            </div>
          )}
        </div>
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      )}
    </>
  );
};
