import React, { useState, useCallback, useEffect } from "react";
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DataTable from 'react-data-table-component';
import {
  FaCalendar,
  FaCheckSquare,
  FaFile,
  FaFont,
  FaHashtag,
  FaObjectGroup,
  FaPlus,
  FaTable,
  FaTrash,
} from "react-icons/fa";
import { customApi } from "../services/api";

function EntitySection({
  entities,
  onCreateEntity,
  onAddField,
  onDeleteEntity,
  onDeleteField,
}) {
  const [selectedEntityId, setSelectedEntityId] = useState(null);
  const [newFieldData, setNewFieldData] = useState({});
  const [error, setError] = useState(null);
  const [newEntityName, setNewEntityName] = useState("");
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [entityData, setEntityData] = useState([]);

  const handleCreateEntity = useCallback(() => {
    if (newEntityName.trim()) {
      setError(null);
      onCreateEntity(newEntityName.trim())
        .then(() => {
          setNewEntityName("");
        })
        .catch((err) => {
          setError(`Failed to create entity: ${err.message}`);
        });
    }
  }, [newEntityName, onCreateEntity]);

  const handleSelectEntity = useCallback((entity) => {
    setSelectedEntityId(entity.id);
    setNewFieldData({
      name: "",
      type: "string",
      required: false,
      autogenerate: false,
    });
    setError(null);
  }, []);

  const handleAddField = useCallback(() => {
    if (newFieldData?.name && selectedEntityId) {
      setError(null);
      onAddField(selectedEntityId, newFieldData)
        .then(() => {
          setNewFieldData({
            name: "",
            type: "string",
            required: false,
            autogenerate: false,
          });
        })
        .catch((err) => {
          setError(`Failed to add field: ${err.message}`);
        });
    }
  }, [newFieldData, selectedEntityId, onAddField]);

  const handleDeleteEntity = useCallback(
    (entityId, event) => {
      event.stopPropagation();
      setError(null);
      onDeleteEntity(entityId)
        .then(() => {
          if (selectedEntityId === entityId) {
            setSelectedEntityId(null);
          }
        })
        .catch((err) => {
          setError(`Failed to delete entity: ${err.message}`);
        });
    },
    [onDeleteEntity, selectedEntityId]
  );

  const handleDeleteField = useCallback(
    (fieldId) => {
      setError(null);
      onDeleteField(selectedEntityId, fieldId).catch((err) => {
        setError(`Failed to delete field: ${err.message}`);
      });
    },
    [onDeleteField, selectedEntityId]
  );

  useEffect(() => {
    setSelectedEntity(
      entities.find((entity) => entity.id === selectedEntityId)
    );
  }, [entities, selectedEntityId]);

  useEffect(() => {
    if (selectedEntityId) {
      customApi.getEntityData(selectedEntityId)
        .then((data) => {
          setEntityData(data.data);
        })
      // Fetch data for the selected entity
      // This is a placeholder. Replace with actual API call
      // setEntityData([
      //   { id: 1, name: 'Sample 1', description: 'Description 1' },
      //   { id: 2, name: 'Sample 2', description: 'Description 2' },
      //   // Add more sample data as needed
      // ]);
    }
  }, [selectedEntityId]);

  const columns = selectedEntity
    ? selectedEntity.fields.map(field => ({
        name: field.name,
        selector: row => (<div title={row[field.name]}>{row[field.name]}</div>),
        sortable: true,
        style: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '300px',
          minWidth: '10px',
          overflow: 'hidden',
          width: 'auto'
        }
      }))
    : [];

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {error && (
        <div
          style={{
            backgroundColor: "#ffcccc",
            color: "red",
            padding: "10px",
            marginBottom: "10px",
          }}
        >
          {error}
        </div>
      )}
      <div style={{ display: "flex", flex: 1, overflow: "hidden" }}>
        <div
          style={{
            width: "30%",
            borderRight: "1px solid #ccc",
            padding: "10px",
            overflowY: "auto",
          }}
        >
          <h3>
            <FaTable /> Entities
          </h3>
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <input
              type="text"
              value={newEntityName}
              onChange={(e) => setNewEntityName(e.target.value)}
              placeholder="Enter entity name"
              style={{ flexGrow: 1, marginRight: "10px", padding: "5px" }}
            />
            <button onClick={handleCreateEntity}>Create</button>
          </div>
          <div>
            {entities.map((entity) => (
              <div
                key={entity.id}
                onClick={() => handleSelectEntity(entity)}
                style={{
                  padding: "5px",
                  marginBottom: "5px",
                  backgroundColor:
                    entity.id === selectedEntityId ? "#e0e0e0" : "transparent",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>
                  <FaTable /> {entity.name}
                </span>
                <FaTrash
                  onClick={(e) => handleDeleteEntity(entity.id, e)}
                  style={{ cursor: "pointer", color: "red" }}
                />
              </div>
            ))}
          </div>
        </div>
        <div style={{ width: "70%", padding: "10px", overflowY: "auto" }}>
          {selectedEntityId && selectedEntity ? (
            <Tabs>
              <TabList>
                <Tab>Fields</Tab>
                <Tab>Data</Tab>
              </TabList>

              <TabPanel>
                <h4>
                  {selectedEntity.name} ({selectedEntityId})
                </h4>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    value={newFieldData.name}
                    onChange={(e) =>
                      setNewFieldData((p) => ({ ...p, name: e.target.value }))
                    }
                    placeholder="Enter field name"
                    style={{ flexGrow: 1, marginRight: "10px", padding: "5px" }}
                  />
                  <select
                    value={newFieldData.type}
                    onChange={(e) =>
                      setNewFieldData((p) => ({ ...p, type: e.target.value }))
                    }
                    style={{ marginRight: "10px", padding: "5px" }}
                  >
                    <option value="string">String</option>
                    <option value="number">Number</option>
                    <option value="boolean">Boolean</option>
                    <option value="date">Date</option>
                    <option value="file">File</option>
                    <option value="object">Object</option>
                  </select>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={newFieldData.required}
                      onChange={(e) =>
                        setNewFieldData((p) => ({
                          ...p,
                          required: e.target.checked,
                        }))
                      }
                      style={{ marginRight: "5px" }}
                    />
                    Required
                  </label>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={newFieldData.autogenerate}
                      onChange={(e) =>
                        setNewFieldData((p) => ({
                          ...p,
                          autogenerate: e.target.checked,
                        }))
                      }
                      style={{ marginRight: "5px" }}
                    />
                    Auto Generate
                  </label>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={newFieldData.vectorize}
                      onChange={(e) =>
                        setNewFieldData((p) => ({
                          ...p,
                          vectorize: e.target.checked,
                        }))
                      }
                      style={{ marginRight: "5px" }}
                    />
                    Vectorize
                  </label>
                  <FaPlus
                    onClick={handleAddField}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div>
                  {selectedEntity.fields.map((field) => (
                    <div
                      key={field.id}
                      style={{
                        marginBottom: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {field.type === "string" ? (
                          <FaFont />
                        ) : field.type === "number" ? (
                          <FaHashtag />
                        ) : field.type === "boolean" ? (
                          <FaCheckSquare />
                        ) : field.type === "date" ? (
                          <FaCalendar />
                        ) : field.type === "object" ?  (<FaObjectGroup />): (
                          <FaFile />
                        )}{" "}
                        <strong>{field.name}</strong> ({field.type})
                        {field.required && (
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *
                          </span>
                        )}
                      </span>
                      <FaTrash
                        onClick={() => handleDeleteField(field.id)}
                        style={{ cursor: "pointer", color: "red" }}
                      />
                    </div>
                  ))}
                </div>
              </TabPanel>

              <TabPanel>
                <h4>Data for {selectedEntity.name}</h4>
                <DataTable
                  columns={columns}
                  data={entityData}
                  pagination
                  highlightOnHover
                  responsive

                />
              </TabPanel>
            </Tabs>
          ) : (
            <p>Select an entity to view and edit its fields and data</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(EntitySection);
